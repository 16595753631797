import { navigate } from "@reach/router"
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"
import { nanoid } from "nanoid"

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: parseInt(process.env.REACT_APP_REQUEST_TIMEOUT || "30000"),
})
//dùng để get user trong page tiếp theo
let pagingtoken: string | undefined = undefined
//dùng để get transaction của user = unifiusername
let unifiUsername: string | undefined = undefined

const defaultHeader = {
  "content-type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
  "Access-Control-Allow-Headers":
    "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
}
instance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const token = await sessionStorage.getItem("jwtToken")
    const username = await sessionStorage.getItem("username")
    config.headers = { ...defaultHeader }
    config.headers["username"] = username
    config.headers["requestId"] = nanoid()
    config.headers["Authorization"] = `Bearer ${token}`
    if (pagingtoken) {
      config.headers["pagingtoken"] = pagingtoken
    }
    console.log(unifiUsername)

    if (unifiUsername) {
      config.headers["unifiusername"] = unifiUsername
    }
    return config
  },
  (error: AxiosError) => {
    console.log("error: ", error)
    console.log("error: ", error?.code)

    return Promise.reject(error)
  }
)
instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response.data
  },
  (error: AxiosError) => {
    if (error.response?.status === 403) {
      sessionStorage.clear()
      navigate("/", { replace: true })
    }
    return Promise.reject({ error, code: error.response?.status })
  }
)

export const setPaggingToken = (pagingToken: string | undefined): void => {
  pagingtoken = pagingToken
}
export const setUnifiUsername = (username: string | undefined): void => {
  unifiUsername = username
}
export default instance
