import { navigate, RouteComponentProps } from "@reach/router"
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js"
import { Button, Form, Input, message } from "antd"
import React from "react"
import * as yup from "yup"
import logo from "../../assets/logo.png"
import { UserContext } from "../../context/auth"
import UserPool from "./Authentication/PoolUser"
import styles from "./styles.module.css"

interface LoginScreenState {
  loading: boolean
  switchAuthentication: boolean
}

const schema = yup.object().shape({
  username: yup
    .string()
    .required("Username not empty. Please enter it and try again!"),
  password: yup
    .string()
    .required("Password not empty. Please enter it and try again!")
    .min(24, "Password least 24 character. Please try again!"),
})

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 50 },
}
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
}

export default class LoginScreen extends React.Component<
  RouteComponentProps,
  LoginScreenState
> {
  constructor(props: RouteComponentProps) {
    super(props)
    this.state = {
      loading: false,
      switchAuthentication: false,
    }
  }

  onFinish = async (values: any, login: () => void) => {
    this.setState({ loading: true })
    const { username, password } = values
    try {
      await schema.validate({ username, password })
      // const res: any = await HTTPRequest.post("/auth/login", {
      //   name: username,
      //   password,
      // })
      // this.setState({ loading: false })
      // if (res.accessToken && res.accessToken.jwtToken) {
      //   await sessionStorage.setItem("jwtToken", res.accessToken.jwtToken)
      //   await sessionStorage.setItem("username", username)
      //   login()
      //   navigate('/security', { replace: true })
      // } else {
      //   //message.error(res.message)
      //   navigate('/security', { replace: true })
      // }
      const user: any = new CognitoUser({
        Username: username,
        Pool: UserPool,
      })
      const authDetails: any = new AuthenticationDetails({
        Username: username,
        Password: password,
      })

      user.authenticateUser(authDetails, {
        onSuccess: async (data: any) => {
          if (data?.accessToken) {
            await sessionStorage.setItem("jwtToken", data.accessToken.jwtToken)
            await sessionStorage.setItem("username", username)
            login()
            navigate("/securityScanQrCode", { replace: true })
          }

          this.setState({ loading: false })
        },

        onFailure: async (err: any) => {
          if (err && err.message === "Incorrect username or password.") {
            message.error(
              "Sorry! That username / password combination is not valid"
            )
            this.setState({ loading: false })
          } else {
            navigate("/securityCode", {
              state: { Username: username, Password: password },
              replace: true,
            })
            this.setState({ loading: false })
          }
        },
      })
    } catch (error: any) {
      this.setState({ loading: false })
      message.error(error.message)
    }
  }

  handleSwitch = (): void => {
    this.setState({ switchAuthentication: !this.state.switchAuthentication })
  }

  onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo)
  }

  render() {
    const { loading } = this.state
    return (
      <UserContext.Consumer>
        {({ login }) => (
          <div className={styles.container}>
            <img
              className={styles.imgLogo}
              alt="unifimoney logo"
              width="126"
              height="36"
              src={logo}
            />
            <Form
              style={{ marginTop: 20 }}
              {...layout}
              name="basic"
              onFinish={(value: any) => this.onFinish(value, login)}
              onFinishFailed={this.onFinishFailed}
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
              >
                <Input maxLength={255} allowClear />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password maxLength={255} />
              </Form.Item>
              {/* <Form.Item >
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Text>Two-Factor Authentication</Text>
                  <Switch
                    checkedChildren="Enable"
                    unCheckedChildren="Disable"
                    //defaultChecked={record.geminiStatus === 1}
                    onClick={() => this.handleSwitch()}
                  />
                </div>
              </Form.Item> */}
              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
      </UserContext.Consumer>
    )
  }
}
