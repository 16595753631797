import { Link, navigate, RouteComponentProps, Router } from '@reach/router'
import { Layout, Menu } from 'antd'
import { createBrowserHistory } from 'history'
import React, { ReactNode, useEffect, useState } from 'react'
import {
  AiFillBank,
  AiOutlineBook,
  AiOutlineBarChart,
  AiOutlineHome,
  AiOutlineLineChart,
  AiOutlineDesktop
} from 'react-icons/ai'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import logo from '../assets/logo.png'
import NavBar from '../components/NavBar'
import { UserContext } from '../context/auth'
import { persistor, store } from '../redux/store'
import DashboardScreen from './DashboardScreen'
import ForbiddenPage from './ForbiddenPage'
import LoginScreen from './LoginScreen'
import SecurityCode from './LoginScreen/SecurityCode'
import SecurityQRCode from './LoginScreen/SecurityQRCode'
import NotFoundPage from './NotFoundPage'
import styles from './styles.module.css'
import Tracker from './Tracker'
import Transactions from './Transactions'
import Q2CallbackPage from './Q2Callback'
import FFBPromoCampaign from './FFBPromoCampaign'
import GeminiInvoice from './GeminiInvoice'
import ACHRecReport from './ReconciliationReports/ReportACH'
import PYCRecReport from './ReconciliationReports/ReportPYC/index'

const { Footer, Sider, Content } = Layout
// ts-ignore
export const history = createBrowserHistory()

const DashboardRouters = (props: { children: ReactNode } & RouteComponentProps) => {
  const [username, setUsername] = useState('')

  useEffect(() => {
    const getUsername = async () => {
      const username = (await sessionStorage.getItem('username')) || ''
      setUsername(username)
      if (!username) navigate('/')
    }
    getUsername()
  }, [])

  return (
    <Layout className={styles.container}>
      <NavBar usernam={username} />
      <Layout>
        <Sider className={styles.siteLayoutBackground} breakpoint="lg" collapsedWidth="0">
          <Menu theme="light" mode="inline" defaultSelectedKeys={['0']}>
            <Menu.Item key="0" icon={<AiOutlineHome />} className={styles.menuItem}>
              <Link to="dashboard">Dashboard</Link>
            </Menu.Item>
            <Menu.Item key="1" icon={<AiOutlineBarChart />} className={styles.menuItem}>
              <Link to="transactions">Transactions</Link>
            </Menu.Item>
            <Menu.Item key="2" icon={<AiOutlineLineChart />} className={styles.menuItem}>
              <Link to="tracker">Tracker</Link>
            </Menu.Item>
            <Menu.Item key="3" icon={<AiFillBank />} className={styles.menuItem}>
              <Link to="ffb-promo">FFB Promo Campaign</Link>
            </Menu.Item>
            <Menu.Item key="4" icon={<AiOutlineDesktop />} className={styles.menuItem}>
              <Link to="gemini-invoice">Gemini Invoice</Link>
            </Menu.Item>
            <Menu.SubMenu
              key="5"
              icon={<AiOutlineBook />}
              className={styles.menuItem}
              title="Reconciliation"
            >
              <Menu.Item key="6">
                <Link to="ach-report">ACH AFT xFers</Link>
              </Menu.Item>
              <Menu.Item key="7">
                <Link to="pyc-report">PYC Deposits</Link>
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
        </Sider>
        <Content>{props.children}</Content>
      </Layout>
      <Footer>
        <div className={styles.footerContainer}>
          <img className={styles.logoInFooter} src={logo} alt={''} />
          <div>Copyright 2020 -- All right reserved.</div>
        </div>
      </Footer>
    </Layout>
  )
}

export default class App extends React.Component {
  login = () => {
    this.setState({
      isAuthenticated: true
    })
  }

  logout = () => {
    this.setState({ isAuthenticated: false })
  }
  state = {
    isAuthenticated: false,
    login: this.login,
    logout: this.logout
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <UserContext.Provider value={this.state}>
            <Router>
              <LoginScreen path="/" />
              <SecurityQRCode path="securityScanQrCode" />
              <SecurityCode path="securityCode" />
              <DashboardRouters path="home">
                <DashboardScreen path="dashboard" />
                <Transactions path="transactions" />
                <Tracker path="tracker" />
                <FFBPromoCampaign path="ffb-promo" />
                <GeminiInvoice path="gemini-invoice" />
                <ACHRecReport path="ach-report" />
                <PYCRecReport path="pyc-report" />
              </DashboardRouters>
              <NotFoundPage default />
              <ForbiddenPage path="forbidden" />
              <Q2CallbackPage path="q2-callback" />
            </Router>
          </UserContext.Provider>
        </PersistGate>
      </Provider>
    )
  }
}
