import { AxiosResponse } from 'axios'

import HTTPRequest from './index'
import { APIs } from '../constants'

const PromoServices = {
  getGeminiInvoice: (
    offset: number,
    limit: number,
    unifi_username: string,
    fi_name: string,
    onboard_date: string,
    from_date_invested: string,
    to_date_invested: string,
    amount_invested: string,
    invested_within_days: string,
    trading_requirement: string,
    from_date_onboard: string,
    to_date_onboard: string
  ): Promise<AxiosResponse['data']> => {
    const params = {
      offset,
      limit,
      unifi_username,
      fi_name,
      onboard_date,
      from_date_invested,
      to_date_invested,
      amount_invested,
      invested_within_days,
      trading_requirement,
      from_date_onboard,
      to_date_onboard
    }

    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(
        ([_, value]) => value !== undefined && value !== null && value !== ''
      )
    )
    return HTTPRequest.get(APIs.GET_V2_GEMINI_INVOICE, { params: filteredParams })
  },

  getPartners(): Promise<AxiosResponse['data']> {
    return HTTPRequest.get(APIs.GET_V2_GEMINI_PARTNER)
  }
}

export default PromoServices
