import HTTPRequest from './index'
import { APIs } from '../constants'
import { RoboAccountBalanceResponse, RoboTransactionListResponse } from 'src/types'

const RoboServices = {
  getDWRoboAccountBalance: (): Promise<RoboAccountBalanceResponse> => {
    return HTTPRequest.get(APIs.GET_DW_ROBO_ACCOUNT_BALANCE)
  },
  getApexRoboAccountBalance: (): Promise<RoboAccountBalanceResponse> => {
    return HTTPRequest.get(APIs.GET_APEX_ROBO_ACCOUNT_BALANCE)
  },
  getDWRoboTransactionList: (): Promise<RoboTransactionListResponse> => {
    return HTTPRequest.get(APIs.GET_DW_TRANSACTION_LIST, {
      params: {
        type: 'robo'
      }
    })
  },
  getApexRoboTransactionList: (): Promise<RoboTransactionListResponse> => {
    return HTTPRequest.get(APIs.GET_APEX_TRANSACTION_LIST, {
      params: {
        type: 'robo'
      }
    })
  }
}

export default RoboServices
