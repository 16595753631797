import React from "react"
import styles from "./styles.module.css"
import logo from "../../assets/logo.png"
import { FaUserCircle } from "react-icons/fa"
import { Menu, Dropdown } from "antd"
import { AiOutlineLogout } from "react-icons/ai"
import { navigate } from "@reach/router"

interface NavBarProp {
  usernam: string
}

const menu = (
  <Menu>
    <Menu.Item key="0" icon>
      <div
        className={styles.row}
        onClick={() => {
          sessionStorage.clear()
          navigate('/', {replace: true})
        }}
      >
        Logout
        <AiOutlineLogout className={styles.logoutIcon} />
      </div>
    </Menu.Item>
  </Menu>
)

const NavBar = (props: NavBarProp) => {
  const { usernam } = props
  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <img alt="unifimoney logo" width="126" height="36" src={logo} />
      </div>
      <div className={styles.avatarContainer}>
        <Dropdown overlay={menu} trigger={["click"]}>
          <FaUserCircle size={24} />
        </Dropdown>
      </div>
      <div className={styles.userText}>{usernam}</div>
    </div>
  )
}

export default NavBar
