import { setPaggingToken } from "../../api"
import UserServices from "../../api/UserServices"
import initStateReducer from "../initStateReducer"

const initState = {
  ...initStateReducer,
  pagingToken: null,
}
const LIST_USER = "LIST_USER"
const LIST_USER_SUCCESS = "LIST_USER_SUCCESS"
const LIST_USER_ERROR = "LIST_USER_ERROR"
const RESET_LIST_USER = "RESET_LIST_USER"

const listUserAction =
  () =>
  (
    dispatch: (arg0: { type: string; payload?: any; message?: any }) => void
  ) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: LIST_USER })
      return UserServices.getUserList()
        .then(data => {
          dispatch({
            type: LIST_USER_SUCCESS,
            payload: data,
            message: "Success",
          })
          resolve(true)
        })
        .catch(error => {
          console.log(error)
          reject(error)
          dispatch({ type: LIST_USER_ERROR, message: error?.message })
        })
    })
  }
const listUserActionReset =
  () =>
  (
    dispatch: (arg0: { type: string; payload?: any; message?: any }) => void
  ) => {
    dispatch({ type: RESET_LIST_USER })
  }
const listUserReducer = (
  state = initState,
  action: { type: any; payload: any; message: any }
) => {
  switch (action.type) {
    case LIST_USER:
      return { ...state, isLoading: true }
    case LIST_USER_SUCCESS:
      if (!action.payload.pagingToken) {
        console.log("token null: ", action.payload)
      }
      setPaggingToken(action.payload.pagingToken)

      const isFirstPage = !state.pagingToken && state.data.length === 0
      const isMiddlePage = state.pagingToken && state.data.length > 0
      const isLastPage = !state.pagingToken && state.data.length > 0

      if (isFirstPage) {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          data: action.payload.users,
          pagingToken: action.payload.pagingToken,
          message: action.message,
        }
      }

      if (isMiddlePage || isLastPage) {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          data: [...state.data, ...action.payload.users],
          pagingToken: action.payload.pagingToken,
          message: action.message,
        }
      }
      return state
    case LIST_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        message: action.message,
      }
    case RESET_LIST_USER:
      return initState
    default:
      return state
  }
}

export { listUserAction, listUserActionReset, listUserReducer }
