import { navigate } from "@reach/router"
import { Button, Input, message, notification, Typography } from "antd"
import React from "react"
import HTTPRequest from "../../api"
import ThreeDotsWave from "./ThreeDotsWave"

interface SecurityQRCodeState {
  loading: boolean
  value: string
  valueQRcode: string
  loadingButton: boolean
}

const { Title, Text, Link } = Typography

export default class SecurityQRCode extends React.Component<
  any,
  SecurityQRCodeState
> {
  constructor(props: any) {
    super(props)
    this.state = {
      loading: true,
      value: "",
      valueQRcode: "",
      loadingButton: false,
    }
  }

  async componentDidMount() {
    try {
      const valueQRcode: any = await HTTPRequest.get("/auth/qr-code")
      if (valueQRcode) {
        this.setState({ loading: false, valueQRcode })
        return
      } else {
        message.error(valueQRcode.message)
      }
    } catch (error: any) {
      notification["error"]({
        message: "ERROR",
        description: error.error.message,
      })
      this.setState({ loading: false, valueQRcode: "" })
    }
  }

  onChange = (e: any): void => {
    const { value } = e.target
    const reg = /^-?\d*(\.\d*)?$/
    if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
      this.setState({ value })
      return
    }
  }

  HandleClick = async (): Promise<void> => {
    this.setState({ loadingButton: true })
    try {
      const res: any = await HTTPRequest.post("/auth/enable-mfa", {
        userCode: this.state.value,
      })

      this.setState({ loadingButton: false })

      if (res && res.Status === "SUCCESS") {
        navigate("/home/dashboard", { replace: true })
      } else {
        message.error(res.message)
      }
    } catch (error: any) {
      notification["error"]({
        message: "ERROR",
        description: error.error.message,
      })
      this.setState({ loadingButton: false })
    }
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          paddingTop: "9%",
          justifyContent: "center",
        }}
      >
        {this.state.loading ? (
          <ThreeDotsWave />
        ) : (
          <div
            style={{ flexDirection: "column", display: "flex", width: "35%" }}
          >
            <Title
              style={{ color: "#1E90FF", fontSize: 50, alignSelf: "center" }}
            >
              Security Code
            </Title>

            <Text style={{ fontSize: 22, textAlign: "center" }}>
              Please download Google Authenticator application from
              <Link
                href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                target="_blank"
                style={{ color: "skyblue" }}
                keyboard={true}
              >
                App Store
              </Link>
              or
              <Link
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=vi&gl=US"
                target="_blank"
                style={{ color: "skyblue" }}
                keyboard={true}
              >
                Google Play
              </Link>
              and then scan the following QR code
            </Text>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={this.state.valueQRcode} alt="" />
            </div>

            <Input
              value={this.state.value}
              {...this.props}
              maxLength={6}
              onChange={this.onChange}
              style={{ width: "65%", alignSelf: "center" }}
            />

            <Button
              onClick={() => this.HandleClick()}
              style={{ marginTop: "4%", width: "25%", alignSelf: "center" }}
              type="primary"
              htmlType="submit"
              loading={this.state.loadingButton}
            >
              Continue
            </Button>
          </div>
        )}
      </div>
    )
  }
}
