import { Col, Row } from 'antd'
import clsx from 'clsx'
import { useState } from 'react'
import { Cognito } from 'src/types'
import { capitalizeCamelCase, convertTitleCase } from 'src/utils/format'
import styles from './styles.module.css'

export interface KYCTabContentProps {
  userCognito: Cognito
}

export default function KYCTabContent({ userCognito }: KYCTabContentProps) {
  const [showReason, setShowReason] = useState({
    astra: false,
    robo: false,
    self: false,
    sardine: false,
    gemini: false
  })

  const KYC_LIST = [
    {
      name: 'Astra',
      title: 'Astra KYC: ',
      value: userCognito?.kycResult?.astraKyc?.status || 'N/A',
      reason: userCognito?.kycResult?.astraKyc?.reason,
      isFailed: userCognito?.kycResult?.astraKyc?.status?.trim()?.toLowerCase() === 'failed',
      showReason: showReason.astra,
      setShowReason: () => setShowReason((prev) => ({ ...prev, astra: !prev.astra }))
    },
    {
      name: 'Robo',
      title: 'Apex Robo KYC: ',
      value: userCognito?.kycResult?.roboApexKyc?.status || 'N/A',
      reason: userCognito?.kycResult?.roboApexKyc?.reason,
      isFailed: userCognito?.kycResult?.roboApexKyc?.status?.trim()?.toLowerCase() === 'failed',
      showReason: showReason.robo,
      setShowReason: () => setShowReason((prev) => ({ ...prev, robo: !prev.robo }))
    },
    {
      name: 'Gemini',
      title: 'Gemini KYC: ',
      value: userCognito?.kycResult?.geminiKyc?.status || 'N/A',
      reason: userCognito?.kycResult?.geminiKyc?.reason,
      isFailed: userCognito?.kycResult?.geminiKyc?.status?.trim()?.toLowerCase() === 'failed',
      showReason: showReason.gemini,
      setShowReason: () => setShowReason((prev) => ({ ...prev, gemini: !prev.gemini }))
    },
    {
      name: 'Sardine',
      title: 'Sardine KYC: ',
      value: userCognito?.kycResult?.sardineKyc?.status || 'N/A',
      reason: userCognito?.kycResult?.sardineKyc?.reason,
      isFailed: userCognito?.kycResult?.sardineKyc?.status?.trim()?.toLowerCase() === 'failed',
      showReason: showReason.sardine,
      setShowReason: () => setShowReason((prev) => ({ ...prev, sardine: !prev.sardine }))
    },
    {
      name: 'Self',
      title: 'Apex Self-elected KYC: ',
      value: userCognito?.kycResult?.selfApexKyc?.status || 'N/A',
      reason: userCognito?.kycResult?.selfApexKyc?.reason,
      isFailed: userCognito?.kycResult?.selfApexKyc?.status?.trim()?.toLowerCase() === 'failed',
      showReason: showReason.self,
      setShowReason: () => setShowReason((prev) => ({ ...prev, self: !prev.self }))
    }
  ]

  return (
    <>
      <Row>
        <Col span={24}>
          <div className={styles.overallKYC}>
            <span className={styles.title}>Overall KYC: </span>
            <span
              className={clsx(styles.value, {
                [styles.textRed]:
                  userCognito?.kycResult?.overallKyc?.trim()?.toLowerCase() === 'failed',
                [styles.textYellow]:
                  userCognito?.kycResult?.overallKyc?.trim()?.toLowerCase() === 'pending',
                [styles.textGreen]:
                  userCognito?.kycResult?.overallKyc?.trim()?.toLowerCase() === 'complete',
                [styles.textBlue]:
                  userCognito?.kycResult?.overallKyc?.trim()?.toLowerCase() === 'incomplete'
              })}
            >
              {userCognito?.kycResult?.overallKyc || 'N/A'}
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <div className={styles.wrapperLeft}>
            {KYC_LIST.filter((item) => ['Astra', 'Robo', 'Gemini'].includes(item.name)).map(
              (item) => (
                <div className={styles.wrapperKYC}>
                  <div className={styles.statusKYC}>
                    <span className={styles.title}>{item.title}</span>

                    <span
                      className={clsx(styles.value, {
                        [styles.textRed]: item.isFailed
                      })}
                    >
                      {item.value}
                    </span>

                    {item.isFailed && (
                      <span className={styles.viewReason} onClick={item.setShowReason}>
                        {`(${item.showReason ? 'Hide' : 'View'} reason)`}
                      </span>
                    )}
                  </div>

                  {item.showReason && item.reason && (
                    <div className={styles.reason}>{item.reason}</div>
                  )}
                </div>
              )
            )}
          </div>
        </Col>
        <Col span={12}>
          <div className={styles.wrapperRight}>
            {KYC_LIST.filter((item) => ['Sardine', 'Self'].includes(item.name)).map((item) => (
              <div className={styles.wrapperKYC}>
                <div className={styles.statusKYC}>
                  <span className={styles.title}>{item.title}</span>

                  <span
                    className={clsx(styles.value, {
                      [styles.textRed]: item.isFailed
                    })}
                  >
                    {item.value}
                  </span>

                  {item.isFailed && (
                    <span className={styles.viewReason} onClick={item.setShowReason}>
                      {`(${item.showReason ? 'Hide' : 'View'} reason)`}
                    </span>
                  )}
                </div>

                {item.name === 'Sardine'
                  ? item.showReason &&
                    Array.isArray(item.reason) &&
                    item.reason.map((item) => (
                      <div key={item.key} className={styles.wrapperReason}>
                        <div className={styles.key}>{`${capitalizeCamelCase(item.key)}: `}</div>
                        <div className={styles.sardineValue}>
                          {convertTitleCase(item.value, '_')}
                        </div>
                      </div>
                    ))
                  : item.showReason &&
                    item.reason && <div className={styles.reason}>{item.reason}</div>}
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </>
  )
}
