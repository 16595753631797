import HTTPRequest from "./index"
import { AxiosResponse } from "axios"
import { APIs } from "../constants"

const TrackerServices = {
  getUserTracking: (): Promise<AxiosResponse["data"]> => {
    return HTTPRequest.get(APIs.GET_USER_TRACKING)
  },
}

export default TrackerServices
