import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import CryptoServices from "../../api/CryptoServices"
import { RecurringOrder } from "../../containers/AllRecurringBuys"

export interface RecurringState {
  allRecurringBuysList: Array<RecurringOrder>
}

const initialState: RecurringState = {
  allRecurringBuysList: [],
}

export const recurringSlice = createSlice({
  name: "recurring",
  initialState,
  reducers: {
    setAllRecurringBuysList: (
      state,
      action: PayloadAction<Array<RecurringOrder>>
    ) => {
      state.allRecurringBuysList = action.payload
    },
  },
})
export const fetchRecurringOrders = createAsyncThunk(
  "recurring/fetchRecurringOrders",
  async (params, thunkAPI) => {
    const recurringList = await CryptoServices.getAllRecurringBuys()

    if (recurringList) {
      thunkAPI.dispatch(setAllRecurringBuysList(recurringList))
    }
  }
)
// Actions
export const { setAllRecurringBuysList } = recurringSlice.actions

// Reducer
const recurringReducer = recurringSlice.reducer
export default recurringReducer
