import { navigate, RouteComponentProps } from "@reach/router"
import { notification, Table, TablePaginationConfig } from "antd"
// import pagination from 'antd/lib/pagination'
import { TableCurrentDataSource } from "antd/lib/table/interface"
import { nanoid } from "nanoid"
import React from "react"
// import { render } from 'react-dom'
import HTTPRequest from "../../api"
import { sortDateIsSameOrBefore } from "../../utils/sort"
interface TransactionsState {
  loading: boolean
  error: string
  data: Array<Transaction & { key: string }>
  columns: any
  sortedInfo: any
}

interface Transaction {
  amount: string
  created: string
  lastUpdated: string
  pairId: string
  place: string
  status: string
  unifiUsername: string
}

class Transactions extends React.Component<
  RouteComponentProps,
  TransactionsState
> {
  constructor(props: RouteComponentProps) {
    super(props)
    this.state = {
      loading: false,
      error: "",
      data: [],
      columns: [],
      sortedInfo: { order: "ascend", columnKey: "" },
    }
  }
  async componentDidMount() {
    const { sortedInfo } = this.state
    try {
      this.setState({ loading: true })
      const res: Array<Transaction> = await HTTPRequest.get("/transactions")

      console.log("response: ", res)
      const columns: any = [
        {
          title: "Amount",
          dataIndex: "amount",
          key: "Amount",
          sorter: (a: Transaction, b: Transaction) => {
            return parseFloat(a.amount) - parseFloat(b.amount)
          },
          sortOrder: sortedInfo.columnKey === "Amount" && sortedInfo.order,
          ellipsis: true,
        },
        {
          title: "Created Date",
          dataIndex: "created",
          key: "CreatedDate",
          sorter: (a: Transaction, b: Transaction) =>
            sortDateIsSameOrBefore(a.created, b.created),
          sortOrder: sortedInfo.columnKey === "CreatedDate" && sortedInfo.order,
          ellipsis: true,
        },
        {
          title: "Last Updated Date",
          dataIndex: "lastUpdated",
          key: "LastUpdatedDate",
          sorter: (a: Transaction, b: Transaction) =>
            sortDateIsSameOrBefore(a.lastUpdated, b.lastUpdated),
          sortOrder:
            sortedInfo.columnKey === "LastUpdatedDate" && sortedInfo.order,
          ellipsis: true,
        },
        {
          title: "Pair ID",
          dataIndex: "pairId",
          key: "pairId",
          ellipsis: true,
        },
        {
          title: "Place",
          dataIndex: "place",
          key: "Place",
          ellipsis: true,
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          ellipsis: true,
        },
        {
          title: "UnifiUsername",
          dataIndex: "unifiUsername",
          key: "unifiUsername",
          ellipsis: true,
        },
      ]

      this.setState({
        columns,
        data: res.map(item => ({ key: nanoid(), ...item })),
      })
    } catch (error: any) {
      notification["error"]({
        message: "ERROR",
        description: error.error && error.error.message,
      })
      console.log("error: ", error)
      if (error.code === "403") navigate("/forbidden")
    } finally {
      this.setState({ loading: false })
    }
  }

  handleChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, (string | number | boolean)[] | null>,
    sorter: any,
    extra: TableCurrentDataSource<Transaction & { key: string }>
  ): void | undefined => {
    console.log("Various parameters", pagination, filters, sorter, extra)
    const columns: any = [
      {
        title: "Amount",
        dataIndex: "amount",
        key: "Amount",
        sorter: (a: Transaction, b: Transaction) => {
          return parseFloat(a.amount) - parseFloat(b.amount)
        },
        sortOrder: sorter.columnKey === "Amount" && sorter?.order,
        ellipsis: true,
      },
      {
        title: "Created Date",
        dataIndex: "created",
        key: "CreatedDate",
        sorter: (a: Transaction, b: Transaction) =>
          sortDateIsSameOrBefore(a.created, b.created),
        sortOrder: sorter.columnKey === "CreatedDate" && sorter.order,
        ellipsis: true,
      },
      {
        title: "Last Updated Date",
        dataIndex: "lastUpdated",
        key: "LastUpdatedDate",
        sorter: (a: Transaction, b: Transaction) =>
          sortDateIsSameOrBefore(a.lastUpdated, b.lastUpdated),
        sortOrder: sorter.columnKey === "LastUpdatedDate" && sorter.order,
        ellipsis: true,
      },
      {
        title: "Pair ID",
        dataIndex: "pairId",
        key: "pairId",
        ellipsis: true,
      },
      {
        title: "Place",
        dataIndex: "place",
        key: "Place",
        ellipsis: true,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        ellipsis: true,
      },
      {
        title: "UnifiUsername",
        dataIndex: "unifiUsername",
        key: "unifiUsername",
        ellipsis: true,
      },
    ]
    this.setState({
      sortedInfo: sorter,
      columns,
    })
  }

  render() {
    return (
      <div>
        <Table
          dataSource={this.state.data}
          columns={this.state.columns}
          onChange={this.handleChange}
          loading={this.state.loading}
        />
      </div>
    )
  }
}

export default Transactions
