import React from "react"
import { Button, Result } from "antd"
import { UserContext } from "../../context/auth"
import {RouteComponentProps, navigate} from '@reach/router'

const NotFoundPage = (props: RouteComponentProps) => {
  const goBackHomeClick = async (login: () => void) => {
    const token = await sessionStorage.getItem("jwtToken")
    if (token) {
      login()
      navigate("/home/dashboard")
    } else navigate("/")
  }
  return (
    <UserContext.Consumer>
      {({ login }) => (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button onClick={() => goBackHomeClick(login)}>Back Home</Button>
          }
        />
      )}
    </UserContext.Consumer>
  )
}

export default NotFoundPage
