import HTTPRequest from './index'
import { APIs } from '../constants'
import { GBIHoldingResponse, GBITransactionResponse } from 'src/types'

const MetalServices = {
  getTransactionList: (): Promise<GBITransactionResponse> => {
    return HTTPRequest.get(APIs.GET_GBI_TRANSACTION_LIST)
  },
  getGBIAccountHolding: (): Promise<GBIHoldingResponse> => {
    return HTTPRequest.get(APIs.GET_GBI_ACCOUNT_HOLDING)
  }
}

export default MetalServices
