import { DownloadOutlined } from '@ant-design/icons'
import { navigate } from '@reach/router'
import { Button, Input, notification, Popconfirm, Select, Switch, Table, Tag, Tooltip } from 'antd'
import { nanoid } from 'nanoid'
import React from 'react'
import { connect } from 'react-redux'
import HTTPRequest, { setUnifiUsername } from '../../api'
import UserServices from '../../api/UserServices'
import { listUserAction, listUserActionReset } from '../../redux/reducer/userList'
import { sortAlphaBeta, sortDateIsSameOrBefore } from '../../utils/sort'
import styles from './styles.module.css'
import TransactionModal from './TransactionModal'

const { Option } = Select

interface User {
  username: string
  enabled: boolean
  userStatus: string
  birthdate: string
  email: string
  email_verified: string
  phone_number: string
  profile: string
  userCreateDate: string
  userLastModifiedDate: string
  geminiStatus: any
}

interface TableSellProp extends User {
  key: string
}

interface DashboardScreenState {
  users: Array<TableSellProp>
  usersSearching: Array<TableSellProp>
  loading: boolean
  error: string
  showSortMenu: boolean
  sortedInfo: any
  filteredInfo: any
  searchText: string
  results: Array<TableSellProp>
  searchField: string
  countResult: number
  countUser: number
  disableKey: string
  loadingKey: string
  disableGeminiKey: string
  loadingGeminiKey: string
  visibleTransactionModal: boolean
  transactionUserName: any
  userSelect: any
  loadingUserSuccess: boolean
  loadingExportZeroBalance: boolean
}
class DashboardScreen extends React.PureComponent<any, DashboardScreenState> {
  static whyDidYouRender = true

  constructor(props: any) {
    super(props)
    this.state = {
      users: [],
      usersSearching: [],
      loading: true,
      error: '',
      showSortMenu: false,
      sortedInfo: {},
      filteredInfo: {},
      searchText: '',
      results: [],
      searchField: 'search_0',
      countResult: 0,
      countUser: 0,
      disableKey: '',
      loadingKey: '',
      disableGeminiKey: '',
      loadingGeminiKey: '',
      visibleTransactionModal: false,
      transactionUserName: null,
      userSelect: {},
      loadingUserSuccess: false,
      loadingExportZeroBalance: false
    }
  }
  componentWillUnmount = () => {
    // this.props.listUserActionReset()
  }
  componentDidMount = () => {
    // check token cũ nếu không cớ tức là page 1, xử lý data để hiện luôn
    if (!this.isLastPage()) {
      this.props.listUserAction()
    } else {
      this.setState({
        loadingUserSuccess: true
      })
    }

    if (this.props.listUserReducer?.data?.length > 0) {
      this.mapDataUser()
    }
  }

  isFirstPage = () => {
    return !this.props.listUserReducer.pagingToken && this.props.listUserReducer?.data?.length === 0
  }

  isMiddlePage = () => {
    return this.props.listUserReducer.pagingToken && this.props.listUserReducer?.data?.length > 0
  }

  isLastPage = () => {
    return !this.props.listUserReducer.pagingToken && this.props.listUserReducer?.data?.length > 0
  }

  mapDataUser = (): void => {
    try {
      const users = this.props.listUserReducer.data
      var result = users.map((item: any) => {
        return {
          key: nanoid(),
          ...item,
          profile: item?.profile.split(':')[2]
        }
      })
      if(!result)
        result = []
      if(this.state.usersSearching.length > 0) {
        this.state.usersSearching.map((item: any) => {
          if(result.filter((re: any) => re?.username?.trim() === item?.username?.trim()).length <= 0) {
            return result.push({
              key: nanoid(),
              ...item,
              profile: item?.profile.split(':')[2]
            })
          } else 
            return undefined;
        })
      }
      this.setState(
        {
          countResult: result.length,
          countUser: result.length,
          users: result,
          results: result,
          loading: false,
          error: ''
        },
        () => {
          const results = this.searching(this.state.searchText, this.state.searchField)
          this.setState({
            results,
            countResult: results.length
          })
        }
      )
    } catch (error: any) {
      notification['error']({
        message: 'ERROR',
        description: error?.message
      })

      this.setState({
        countResult: 0,
        countUser: 0,
        users: [],
        results: [],
        loading: false,
        error: error?.message
      })
      if (error.code === '403') navigate('/forbidden')
    }
  }

  componentDidUpdate = (PrevProps: any) => {
    if (PrevProps.listUserReducer.pagingToken !== this.props.listUserReducer.pagingToken) {
      if (this.isMiddlePage()) {
        this.mapDataUser()
        this.props.listUserAction()
      }

      //check token hiện tại nếu không cớ tức là page cuối, xử lý data thêm lần nữa
      if (this.isLastPage()) {
        this.mapDataUser()
        this.setState({
          loadingUserSuccess: true
        })
      }
    }
  }
  onSortClick = () => {
    this.setState({ showSortMenu: true })
  }

  handleClickOutside = () => {
    this.setState({ showSortMenu: false })
  }

  handleChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    console.log('Various parameters', pagination, filters, sorter, extra)
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    })
  }

  onSearchInputChange = (event: any) => {
    console.log('search text: ', event.target.value)
    const results = this.searching(event.target.value, this.state.searchField)
    this.setState({
      results,
      searchText: event.target.value,
      countResult: results.length
    })
    if (!this.isLastPage()) {
      this.searchingServer(event.target.value, this.state.searchField)
    }
  }

  searchingServer = async (searchText: string, searchField: string): Promise<void> => {
    if (!searchText) {
      this.setState({
        usersSearching: []
      })
      return;
    }
    var filter = '';
    switch (searchField) {
      case 'search_0':
        filter = `phone_number^="${searchText}"`;
        break;
      case 'search_1':
        filter = `username^="${searchText}"`;
        break;
      case 'search_2':
        filter = `email^="${searchText}"`;
        break;
      case 'search_3':
        filter = `username^="${searchText}"`;
        break;
      case 'search_4':
        filter = `profile^="${searchText}"`;
        break;
      default:
        {
          this.setState({
            usersSearching: []
          })
          return;
        }
    }
    console.log(filter);
    const res = await UserServices.searchingUserList(filter);
    if(res && res?.users.length > 0) {
      this.setState({
        usersSearching: res?.users
      });
      this.mapDataUser();
    }
  }

  searching = (searchText: string, searchField: string): Array<TableSellProp> => {
    const { users } = this.state
    if (!searchText) return users
    switch (searchField) {
      case 'search_0':
        return users.filter((item: TableSellProp) =>
          item.phone_number.toLowerCase().includes(searchText.toLowerCase().trim())
        )
      case 'search_1':
        return users.filter((item: TableSellProp) =>
          item.username.toLowerCase().includes(searchText.toLowerCase().trim())
        )
      case 'search_2':
        return users.filter((item: TableSellProp) =>
          item.email.toLowerCase().includes(searchText.toLowerCase().trim())
        )
      case 'search_3':
        return users.filter((item: TableSellProp) =>
          item.username.toLowerCase().includes(searchText.toLowerCase().trim())
        )
      case 'search_4':
        return users.filter(
          (item: TableSellProp) =>
            item.profile && item.profile.toLowerCase().includes(searchText.toLowerCase().trim())
        )
      default:
        return users
    }
  }

  handleSearchSelectChange = (value: any) => {
    const results = this.searching(this.state.searchText, value)
    this.setState({
      results,
      countResult: results.length,
      searchField: value
    })
    if (!this.isLastPage()) {
      this.searchingServer(this.state.searchText, this.state.searchField)
    }
  }

  handleDisable = async (record: TableSellProp): Promise<void> => {
    // console.log('userrecord', this.state.users.filter((e: any) => e.username === 'fdf4cbd3-0650-4b29-ba6a-f0668d8d3d93'));
    // console.log('record', record);
    try {
      const { users } = this.state
      this.setState({ loadingKey: record.key })
      await HTTPRequest.post('/users', {
        usernameAction: record.username,
        flag: !record.enabled
      })
      const temps = users.map((item) => {
        if (record.key === item.key) return { ...item, enabled: !record.enabled }
        return item
      })

      this.setState({ users: temps }, () => {
        const results = this.searching(this.state.searchText, this.state.searchField)
        this.setState({ results: results })
      })
    } catch (error: any) {
      if (error.error && error.error.message) {
        notification['error']({
          message: 'ERROR',
          description: error.error && error.error.message
        })
      }
    } finally {
      this.setState({ loadingKey: '', disableKey: '' })
    }
  }

  handleDisableDetailSwitch = async (record: TableSellProp): Promise<void> => {
    try {
      this.setState({ loadingKey: record.key })
      await HTTPRequest.post('/users', {
        usernameAction: record.username,
        flag: !record.enabled
      })
      const temps = { ...record, enabled: !record.enabled }
      this.setState({ userSelect: temps })

      const fakeUser = this.state.users.map((item) => {
        if (record.key === item.key) return { ...temps }
        return item
      })
      this.setState({ users: fakeUser }, () => {
        const results = this.searching(this.state.searchText, this.state.searchField)
        this.setState({ results: results })
      })
    } catch (error: any) {
      if (error.error && error.error.message) {
        notification['error']({
          message: 'ERROR',
          description: error.error && error.error.message
        })
      }
    } finally {
      this.setState({ loadingKey: '', disableKey: '' })
    }
  }

  handleCancelConfirm = () => {
    this.setState({ loadingKey: '', disableKey: '' })
  }
  handleDisableGemini = async (record: TableSellProp): Promise<void> => {
    try {
      const { users } = this.state
      this.setState({ loadingGeminiKey: record.key })
      await HTTPRequest.put('/users/update-gemini-status', {
        unifiUsername: record.username,
        status: record.geminiStatus === 1 ? 0 : 1
      })
      const temps = users.map((item) => {
        if (record.key === item.key)
          return { ...item, geminiStatus: record.geminiStatus === 1 ? 0 : 1 }
        return item
      })
      this.setState({ users: temps }, () => {
        const results = this.searching(this.state.searchText, this.state.searchField)
        this.setState({ results: results })
      })
    } catch (error: any) {
      if (error.error && error.error.message) {
        notification['error']({
          message: 'ERROR',
          description: error.error && error.error.message
        })
      }
    } finally {
      this.setState({ loadingGeminiKey: '', disableGeminiKey: '' })
    }
  }

  handleCancelConfirmGemini = () => {
    this.setState({ loadingGeminiKey: '', disableGeminiKey: '' })
  }
  handleSwitchClickGemini = (record: TableSellProp): void => {
    this.setState({ disableGeminiKey: record.key })
  }
  handleSwitchClick = (record: TableSellProp): void => {
    this.setState({ disableKey: record.key })
  }
  openTracsactionModal = (username: string, record: TableSellProp) => (): void => {
    //console.log("username", username);
    setUnifiUsername(username)
    this.setState((state) => ({
      ...state,
      visibleTransactionModal: true,
      transactionUserName: username,
      userSelect: record
    }))

    // this.setState(state => ({

    // }))
  }
  closeTracsactionModal = (): void => {
    this.setState({ visibleTransactionModal: false, userSelect: {} })
  }

  handleZeroBalanceSubmit = async () => {
    try {
      this.setState({ loadingExportZeroBalance: true })
      const res = await UserServices.exportZeroBalance()

      if (Number(res?.data?.code) === 200) {
        this.setState({ loadingExportZeroBalance: false })
        notification.success({
          message: 'Success',
          description: res?.data?.message
        })
      }

      if (res?.error) {
        this.setState({ loadingExportZeroBalance: false })
        notification.error({
          message: 'Error',
          description: res?.error?.message
        })
      }
    } catch (error: any) {
      this.setState({ loadingExportZeroBalance: false })
      notification.error({
        message: 'Error',
        description: error?.message
      })
    }
  }

  render() {
    const { sortedInfo, filteredInfo } = this.state
    const columns = [
      {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
        sorter: (a: TableSellProp, b: TableSellProp) => sortAlphaBeta(a.username, b.username),
        sortOrder: sortedInfo.columnKey === 'username' && sortedInfo.order,
        ellipsis: true,
        width: '18%',
        render: (text: string, record: TableSellProp) => (
          <React.Fragment>
            <div
              style={{ color: 'blue', cursor: 'pointer' }}
              onClick={this.openTracsactionModal(text, record)}
            >
              {text}
            </div>
          </React.Fragment>
        )
      },
      {
        title: 'Phone Number',
        dataIndex: 'phone_number',
        key: 'phone_number',
        ellipsis: true
      },
      {
        title: 'E6 ID',
        dataIndex: 'e6_uid',
        key: 'e6_uid',
        ellipsis: true
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        sorter: (a: TableSellProp, b: TableSellProp) => sortAlphaBeta(a.email, b.email),
        sortOrder: sortedInfo.columnKey === 'email' && sortedInfo.order,
        ellipsis: true
      },
      {
        title: 'Email Verified',
        dataIndex: 'email_verified',
        key: 'email_verified',
        filters: [
          { text: 'true', value: 'true' },
          { text: 'false', value: 'false' }
        ],
        filteredValue: filteredInfo.email_verified || null,
        onFilter: (value: any, record: any) => record.email_verified === value,
        ellipsis: true,
        render: (tag: string) => (
          <>
            <Tag color={tag === 'true' ? 'blue' : 'red'} key={tag}>
              {tag.toLowerCase()}
            </Tag>
          </>
        )
      },
      // {
      //   title: "Birthday",
      //   dataIndex: "birthdate",
      //   key: "birthdate",
      //   width: 150,
      //   ellipsis: true,
      // },
      {
        title: 'Onboarded Date',
        dataIndex: 'userCreateDate',
        key: 'userCreateDate',
        sorter: (a: TableSellProp, b: TableSellProp) =>
          sortDateIsSameOrBefore(a.userCreateDate, b.userCreateDate),
        sortOrder: sortedInfo.columnKey === 'userCreateDate' && sortedInfo.order,
        ellipsis: true,
        width: '18%'
      },
      // {
      //   title: "User Last Modified Date",
      //   dataIndex: "userLastModifiedDate",
      //   key: "userLastModifiedDate",
      //   sorter: (a: TableSellProp, b: TableSellProp) =>
      //     sortDateIsSameOrBefore(
      //       a.userLastModifiedDate,
      //       b.userLastModifiedDate
      //     ),
      //   sortOrder:
      //     sortedInfo.columnKey === "userLastModifiedDate" && sortedInfo.order,
      //   ellipsis: true,
      // },
      {
        title: 'User Status',
        dataIndex: 'userStatus',
        key: 'userStatus',
        filters: [
          { text: 'CONFIRMED', value: 'CONFIRMED' },
          { text: 'UNCONFIRMED', value: 'UNCONFIRMED' }
        ],
        filteredValue: filteredInfo.userStatus || null,
        onFilter: (value: any, record: any) => record.userStatus === value,
        render: (tag: string) => (
          <>
            <Tag color={tag === 'CONFIRMED' ? 'green' : 'red'} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          </>
        ),
        ellipsis: true
      },
      // {
      //   title: "Gemini status",
      //   dataIndex: "geminiStatus",
      //   render: (_: any, record: TableSellProp) => {
      //     if (record.geminiStatus === 2) {
      //       return (
      //         <Tooltip
      //           title={
      //             "The crypto investment account of this user does not exist"
      //           }
      //         >
      //           <Switch
      //             checkedChildren="Enable"
      //             unCheckedChildren="Disable"
      //             defaultChecked={false}
      //             disabled={true}
      //             onClick={() => this.handleSwitchClickGemini(record)}
      //           />
      //         </Tooltip>
      //       )
      //     } else {
      //       return (
      //         <Popconfirm
      //           title={
      //             record.geminiStatus === 1
      //               ? "Are you sure to disable this Gemini account?"
      //               : "Are you sure to enable this Gemini account?"
      //           }
      //           onConfirm={() => this.handleDisableGemini(record)}
      //           visible={record.key === this.state.disableGeminiKey}
      //           okButtonProps={{ loading: record.key === this.state.loadingGeminiKey }}
      //           onCancel={this.handleCancelConfirmGemini}
      //         >
      //           <Switch
      //             checkedChildren="Enable"
      //             unCheckedChildren="Disable"
      //             defaultChecked={record.geminiStatus === 1}
      //             disabled={record.key === this.state.disableGeminiKey}
      //             onClick={() => this.handleSwitchClickGemini(record)}
      //           />
      //         </Popconfirm>
      //       )
      //     }
      //   },
      // },
      {
        title: 'Gemini status',
        dataIndex: 'geminiStatus',
        key: 'geminiStatus',
        render: (_: any, record: TableSellProp) => {
          if (record.geminiStatus === 2) {
            return (
              <Tooltip title={'The crypto investment account of this user does not exist'}>
                <Switch
                  checkedChildren="Enable"
                  unCheckedChildren="Disable"
                  defaultChecked={false}
                  disabled={true}
                  onClick={() => this.handleSwitchClickGemini(record)}
                />
              </Tooltip>
            )
          } else {
            return (
              <Popconfirm
                title={
                  record.geminiStatus === 1
                    ? 'Are you sure to disable this Gemini account?'
                    : 'Are you sure to enable this Gemini account?'
                }
                onConfirm={() => this.handleDisableGemini(record)}
                visible={record.key === this.state.disableGeminiKey}
                okButtonProps={{
                  loading: record.key === this.state.loadingGeminiKey
                }}
                onCancel={this.handleCancelConfirmGemini}
              >
                <Switch
                  checkedChildren="Enable"
                  unCheckedChildren="Disable"
                  defaultChecked={record.geminiStatus === 1}
                  disabled={record.key === this.state.disableGeminiKey}
                  onClick={() => this.handleSwitchClickGemini(record)}
                />
              </Popconfirm>
            )
          }
        }
      },
      {
        title: 'Account status',
        dataIndex: 'status',
        key: 'status',
        render: (_: any, record: TableSellProp) => (
          <Popconfirm
            title={
              record.enabled
                ? 'Are you sure to disable this user?'
                : 'Are you sure to enable this user?'
            }
            onConfirm={() => this.handleDisable(record)}
            visible={record.key === this.state.disableKey && !this.state.visibleTransactionModal}
            okButtonProps={{ loading: record.key === this.state.loadingKey }}
            onCancel={this.handleCancelConfirm}
          >
            <Switch
              checkedChildren="Enable"
              unCheckedChildren="Disable"
              defaultChecked={record.enabled}
              disabled={record.key === this.state.disableKey}
              onClick={() => this.handleSwitchClick(record)}
            />
          </Popconfirm>
        )
      }
    ]

    return (
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <div className={styles.searchFields}>
            <Input
              disabled={this.state.loading}
              placeholder="input search text"
              allowClear
              className={styles.searchInput}
              onChange={this.onSearchInputChange}
            />
            <div className={styles.selectFields}>
              <span className={styles.selectLabel}>Select search fields: </span>
              <Select
                disabled={this.state.loading}
                defaultValue={'search_0'}
                className={styles.selectSearch}
                onChange={this.handleSearchSelectChange}
              >
                <Option value="search_0">Phone number</Option>
                <Option value="search_1">Username</Option>
                <Option value="search_2">Email</Option>
                {/* <Option value="search_3">Customer Name</Option> */}
                <Option value="search_4">UMB DDA Account</Option>
              </Select>
            </div>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              className={styles.exportBtn}
              loading={this.state.loadingExportZeroBalance}
              onClick={this.handleZeroBalanceSubmit}
            >
              Export Zero Balance
            </Button>
          </div>
          <h5 className={styles.resultText}>
            {this.state.countResult !== this.state.countUser &&
              `Results ${this.state.countResult}/${this.state.countUser}`}
          </h5>
          {this.state.results && (
            <>
              {!this.state.loadingUserSuccess && (
                <div className={styles.loadingText}>Loading...</div>
              )}
              <Table
                dataSource={this.state.results}
                columns={columns}
                onChange={this.handleChange}
                loading={this.state.loading}
                scroll={{ x: 2000 }}
              />
            </>
          )}
          <TransactionModal
            onClose={this.closeTracsactionModal}
            userId={this.state.transactionUserName}
            isVisible={this.state.visibleTransactionModal}
            user={this.state.userSelect}
            disableKey={this.state.disableKey}
            loadingKey={this.state.loadingKey}
            handleDisableAppStatus={() => this.handleDisableDetailSwitch(this.state.userSelect)}
            handleCancelConfirmAppStatus={this.handleCancelConfirm}
            handleSwitchClickAppStatus={() => this.handleSwitchClick(this.state.userSelect)}
          ></TransactionModal>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state: { listUserReducer: any }) => {
  return {
    listUserReducer: state.listUserReducer
  }
}

export default connect(mapStateToProps, {
  listUserAction,
  listUserActionReset
})(DashboardScreen)
