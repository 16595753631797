import HTTPRequest from './index'
import { APIs } from '../constants'
import { DDAccountBalanceResponse, TransactionListResponse } from 'src/types'

const E6Services = {
  getE6AccountBalance: (): Promise<DDAccountBalanceResponse> => {
    return HTTPRequest.get(APIs.GET_E6_ACCOUNT_BALANCE)
  },
  getE6TransactionList: (fromDate: string, endDate: string): Promise<TransactionListResponse> => {
    return HTTPRequest.get(APIs.GET_E6_TRANSACTION_LIST, {
      params: {
        fromDate: fromDate,
        endDate: endDate
      }
    })
  }
}

export default E6Services
