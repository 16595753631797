import HTTPRequest from "./index"
import { AxiosResponse } from "axios"
import { APIs } from "../constants"

const PromoServices = {
  getPromoList: (
    page: number,
    limit: number,
    user_name: string,
    customer_type: string,
    deposit_within_days: string,
    trading_requirement: string,
    funded_amount_sort: string,
    onboard_date: string | null,
    funded_date: string | null,
    trade_first_date: string | null,
    credit_issued_date: string | null,
    credit_expiry_date: string | null
  ): Promise<AxiosResponse["data"]> => {
    const params = {
      page,
      limit,
      user_name,
      customer_type,
      deposit_within_days,
      trading_requirement,
      funded_amount_sort,
      onboard_date,
      funded_date,
      trade_first_date,
      credit_issued_date,
      credit_expiry_date
    }

    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([_, value]) => value !== undefined && value !== null && value !== '')
    )
    return HTTPRequest.get(APIs.GET_V2_PROMOTION_BACK_OFFICE, { params: filteredParams })
  }
}

export default PromoServices
