import HTTPRequest from './index'
import { APIs } from 'src/constants'
import { SelfAccountBalanceResponse, SelfTransactionListResponse } from 'src/types'

const SelfServices = {
  getDWSelfAccountBalance: (): Promise<SelfAccountBalanceResponse> => {
    return HTTPRequest.get(APIs.GET_DW_SELF_ACCOUNT_BALANCE)
  },
  getApexSelfAccountBalance: (): Promise<SelfAccountBalanceResponse> => {
    return HTTPRequest.get(APIs.GET_APEX_SELF_ACCOUNT_BALANCE)
  },
  getDWSelfTransactionList: (): Promise<SelfTransactionListResponse> => {
    return HTTPRequest.get(APIs.GET_DW_TRANSACTION_LIST, {
      params: {
        type: 'fractional'
      }
    })
  },
  getApexSelfTransactionList: (): Promise<SelfTransactionListResponse> => {
    return HTTPRequest.get(APIs.GET_APEX_TRANSACTION_LIST, {
      params: {
        type: 'fractional'
      }
    })
  }
}

export default SelfServices
