import HTTPRequest from './index'
import { AxiosResponse } from 'axios'
import { APIs } from '../constants'
import { CognitoData, LinkedBankResponse } from 'src/types'

const UserServices = {
  getUserList: (): Promise<AxiosResponse['data']> => {
    return HTTPRequest.get(APIs.GET_USER_LIST)
  },
  exportZeroBalance: (): Promise<AxiosResponse['data']> => {
    return HTTPRequest.get(APIs.EXPORT_ZERO_BALANCE)
  },
  getUserCognito: (): Promise<CognitoData> => {
    return HTTPRequest.get(APIs.GET_USER_COGNITO)
  },
  getLinkedBank: (): Promise<LinkedBankResponse> => {
    return HTTPRequest.get(APIs.GET_LINKED_BANK)
  },
  searchingUserList: (filter: string): Promise<AxiosResponse['data']> => {
    return HTTPRequest.get(APIs.SEARCHING_USER_LIST, {
      params: {
        filter
      }
    })
  },
}

export default UserServices
