import {
  Descriptions,
  Modal,
  notification,
  Popconfirm,
  Skeleton,
  Switch,
  Table,
  Tabs,
  Tag,
  Tooltip
} from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import CryptoServices from 'src/api/CryptoServices'
import E6Services from 'src/api/E6Services'
import MetalServices from 'src/api/MetalServices'
import RoboServices from 'src/api/RoboServices'
import SelfServices from 'src/api/SelfServices'
import UserServices from 'src/api/UserServices'
import KYCTabContent from 'src/components/KYCTabContent'
import { TabPaneKey } from 'src/constants'
import {
  DDAccountBalance,
  DDAccountTransaction,
  Description,
  GBIHolding,
  GBITransaction,
  LinkedBank,
  RoboAccountBalance,
  RoboTransaction,
  SelfAccountBalance,
  SelfTransaction
} from 'src/types'
import { CryptoAccountBalance, CryptoTransaction } from 'src/types/crypto'
import { formatAmount, formatSymbolCode } from 'src/utils/format'

interface TransactionModalProps {
  isVisible: boolean
  onClose?: () => void
  title?: string
  userId?: string
  user?: any
  handleDisableAppStatus?: () => Promise<void>
  handleCancelConfirmAppStatus?: () => void
  handleSwitchClickAppStatus?: () => void
  disableKey?: string
  loadingKey?: string
}

const columns = [
  {
    title: 'Date',
    dataIndex: 'transferDate',
    key: 'transferDate',
    width: '24%',
    render: (text: string, record: object) => (
      <React.Fragment>
        <div>{text ? moment(text).format('LLL') : ''}</div>
      </React.Fragment>
    )
  },
  {
    title: 'Type',
    dataIndex: 'typeForBO',
    key: 'typeForBO',
    width: '15%',
    render: (text: string, record: object) => (
      <React.Fragment>
        <Tooltip title={text}>
          <Tag color={'green'} key={text}>
            {text.length > 20 ? (text.substring(0, 20) + '...').toUpperCase() : text.toUpperCase()}
          </Tag>
        </Tooltip>
      </React.Fragment>
    )
  },
  {
    title: 'Amount',
    dataIndex: 'transferAmount',
    key: 'transferAmount',
    width: '14%',
    render: (text: number, record: object) => (
      <React.Fragment>
        <div>{formatAmount(text)}</div>
      </React.Fragment>
    )
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: '34%',
    ellipsis: true
  },
  {
    title: 'Status',
    dataIndex: 'statusForBO',
    key: 'statusForBO',
    width: '13%',
    render: (text: string, record: object) => (
      <React.Fragment>
        <Tooltip title={text}>
          <Tag color={'green'} key={text}>
            {text.length > 20 ? (text.substring(0, 20) + '...').toUpperCase() : text.toUpperCase()}
          </Tag>
        </Tooltip>
      </React.Fragment>
    )
  }
]

const columnsDW = [
  {
    title: 'Date',
    dataIndex: 'tranWhen',
    key: 'tranWhen',
    width: '24%',
    render: (text: string, record: object) => (
      <React.Fragment>
        <div>{text ? moment(text).format('LLL') : ''}</div>
      </React.Fragment>
    )
  },
  {
    title: 'Type',
    dataIndex: 'finTranTypeID',
    key: 'finTranTypeID',
    width: '15%',
    render: (text: string, record: object) => (
      <React.Fragment>
        <Tooltip title={text}>
          <Tag color={'green'} key={text}>
            {text.length > 20 ? (text.substring(0, 20) + '...').toUpperCase() : text.toUpperCase()}
          </Tag>
        </Tooltip>
      </React.Fragment>
    )
  },
  {
    title: 'Amount',
    dataIndex: 'tranAmount',
    key: 'tranAmount',
    width: '14%',
    render: (text: number, record: object) => (
      <React.Fragment>
        <div>{formatAmount(text)}</div>
      </React.Fragment>
    )
  },
  {
    title: 'Description',
    dataIndex: 'finTranDesc',
    key: 'finTranDesc',
    width: '34%',
    ellipsis: true
  },
  {
    title: 'Status',
    dataIndex: 'oriTranStatus',
    key: 'oriTranStatus',
    width: '13%',
    render: (text: string, record: object) => (
      <React.Fragment>
        <Tooltip title={text}>
          <Tag color={'green'} key={text}>
            {text.length > 20 ? (text.substring(0, 20) + '...').toUpperCase() : text.toUpperCase()}
          </Tag>
        </Tooltip>
      </React.Fragment>
    )
  }
]

const columnsGEM = [
  {
    title: 'Date',
    dataIndex: 'tranTime',
    key: 'tranTime',
    width: '24%',
    render: (text: string, record: object) => (
      <React.Fragment>
        <div>{text ? moment(text).format('LLL') : ''}</div>
      </React.Fragment>
    )
  },
  {
    title: 'Type',
    dataIndex: 'currency',
    key: 'currency',
    width: '15%',
    render: (text: string, record: object) => (
      <React.Fragment>
        <Tooltip title={text}>
          <Tag color={'green'} key={text}>
            {text.length > 20 ? (text.substring(0, 20) + '...').toUpperCase() : text.toUpperCase()}
          </Tag>
        </Tooltip>
      </React.Fragment>
    )
  },
  {
    title: 'Amount',
    dataIndex: 'tranAmountNotional',
    key: 'tranAmountNotional',
    width: '14%',
    render: (text: string, record: object) => (
      <React.Fragment>
        <div>{text ? formatAmount(+text) : ''}</div>
      </React.Fragment>
    )
  },
  {
    title: 'Description',
    dataIndex: 'tranDesc',
    key: 'tranDesc',
    width: '34%',
    ellipsis: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '13%',
    render: (text: string, record: object) => (
      <React.Fragment>
        <Tooltip title={text}>
          <Tag color={'green'} key={text}>
            {text.length > 20 ? (text.substring(0, 20) + '...').toUpperCase() : text.toUpperCase()}
          </Tag>
        </Tooltip>
      </React.Fragment>
    )
  }
]

const columnsGBI = [
  {
    title: 'Date',
    dataIndex: 'ExecutedOnUtc',
    key: 'ExecutedOnUtc',
    width: '20%',
    render: (text: string, record: object) => (
      <React.Fragment>
        <div>{text ? moment(text).format('LLL') : ''}</div>
      </React.Fragment>
    )
  },
  {
    title: 'Order Code',
    dataIndex: 'OrderCode',
    key: 'OrderCode',
    width: '13%',
    ellipsis: true
  },
  {
    title: 'Side',
    dataIndex: 'Side',
    key: 'Side',
    width: '14%',
    ellipsis: true
  },
  {
    title: 'Quantity',
    dataIndex: 'Quantity',
    key: 'Quantity',
    width: '16%',
    ellipsis: true
  },
  {
    title: 'Amount',
    dataIndex: 'TotalClientFunds',
    key: 'TotalClientFunds',
    width: '12%',
    render: (text: string, record: object) => (
      <React.Fragment>
        <div>{text ? formatAmount(+text) : ''}</div>
      </React.Fragment>
    )
  },
  {
    title: 'State Type',
    dataIndex: 'StateType',
    key: 'StateType',
    width: '10%',
    render: (text: string, record: object) => (
      <React.Fragment>
        <Tooltip title={text}>
          <Tag color={'green'} key={text}>
            {text.length > 20 ? (text.substring(0, 20) + '...').toUpperCase() : text.toUpperCase()}
          </Tag>
        </Tooltip>
      </React.Fragment>
    )
  },
  {
    title: 'Status Type',
    dataIndex: 'StatusType',
    key: 'StatusType',
    width: '15%',
    render: (text: string, record: object) => (
      <React.Fragment>
        <Tooltip title={text}>
          <Tag color={'green'} key={text}>
            {text.length > 20 ? (text.substring(0, 20) + '...').toUpperCase() : text.toUpperCase()}
          </Tag>
        </Tooltip>
      </React.Fragment>
    )
  }
]

const columnsLinkedBanks = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '15%',
    ellipsis: true
  },
  {
    title: 'Bank Name',
    dataIndex: 'institution_name',
    key: 'institution_name',
    width: '10%',
    ellipsis: true
  },
  {
    title: 'Account Name',
    dataIndex: 'official_name',
    key: 'official_name',
    width: '15%',
    ellipsis: true
  },
  {
    title: 'Ending',
    dataIndex: 'mask',
    key: 'mask',
    width: '10%',
    ellipsis: true
  },
  {
    title: 'Subtype',
    dataIndex: 'subtype',
    key: 'subtype',
    width: '10%',
    ellipsis: true
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: '10%',
    ellipsis: true
  },
  {
    title: 'Linked Status',
    dataIndex: 'connection_status',
    key: 'connection_status',
    width: '14%',
    render: (text: string, record: object) => (
      <React.Fragment>
        <Tooltip title={text}>
          <Tag color={'green'} key={text}>
            {text.length > 20 ? (text.substring(0, 20) + '...').toUpperCase() : text.toUpperCase()}
          </Tag>
        </Tooltip>
      </React.Fragment>
    )
  },
  {
    title: 'Updated',
    dataIndex: 'updated_at',
    key: 'updated_at',
    width: '16%',
    render: (text: string, record: object) => (
      <React.Fragment>
        <div>{text ? moment(text).format('LLL') : ''}</div>
      </React.Fragment>
    )
  }
]

const TransactionModal = ({
  isVisible,
  title,
  userId,
  user,
  disableKey,
  loadingKey,
  onClose,
  handleDisableAppStatus,
  handleCancelConfirmAppStatus,
  handleSwitchClickAppStatus
}: TransactionModalProps) => {
  const initialRoboAndSelfAccountBalance = {
    accountNo: '',
    equity: {
      equityValue: 0
    },
    cash: {
      cashBalance: 0
    },
    totalBalance: 0
  }

  const initialDDAccountBalance = {
    accountNumber: '',
    availableBalance: 0,
    availableGifCard: 0,
    availableTradingCredit: 0,
    currentBalance: 0,
    expTradingCredit: '',
    expired: false,
    expiredCountDown: 0,
    isTransfer: false
  }

  const [roboTransactionList, setRoboTransactionList] = useState<Array<RoboTransaction>>([])
  const [roboAccountBalance, setRoboAccountBalance] = useState<RoboAccountBalance>(
    initialRoboAndSelfAccountBalance
  )
  const [selfDirectedAccountBalance, setSelfDirectedAccountBalance] = useState<SelfAccountBalance>(
    initialRoboAndSelfAccountBalance
  )
  const [selfTransactionList, setSelfTransactionList] = useState<Array<SelfTransaction>>([])
  const [dDAccountBalance, setDDAccountBalance] =
    useState<DDAccountBalance>(initialDDAccountBalance)
  const [dDAccountTransactionList, setDDAccountTransactionList] = useState<
    Array<DDAccountTransaction>
  >([])
  const [geminiAccountBalance, setGeminiAccountBalance] = useState<CryptoAccountBalance>({
    gemini_account_name: '',
    gemini_current_balance: 0
  })
  const [dataLinkedBank, setDataLinkedBank] = useState<Array<LinkedBank>>([])
  const [cryptoTransactionList, setCryptoTransactionList] = useState<Array<CryptoTransaction>>([])
  const [gbiAccountHolding, setGbiAccountHolding] = useState<Array<GBIHolding>>([])
  const [gbiTransactionList, setGBITransactionList] = useState<Array<GBITransaction>>([])
  const [userCognito, setUserCognito] = useState<any>({})
  const [selectTabs, setSelectTabs] = useState(TabPaneKey.DD_ACCOUNT)

  const [loadingCognitoUser, setLoadingCognitoUser] = useState(false)
  const [loadingDDAccountTab, setLoadingDDAccountTab] = useState(false)
  const [loadingRoboTab, setLoadingRoboTab] = useState(false)
  const [loadingSelfTab, setLoadingSelfTab] = useState(false)
  const [loadingCryptoTab, setLoadingCryptoTab] = useState(false)
  const [loadingPreciousMetalTab, setLoadingPreciousMetalTab] = useState(false)
  const [loadingLinkedBankTab, setLoadingLinkedBankTab] = useState(false)

  useEffect(() => {
    if (userId) {
      const fetchDataCognito = async () => {
        try {
          setLoadingCognitoUser(true)
          const res = await UserServices.getUserCognito()
          setLoadingCognitoUser(false)
          if (res?.error) {
            return notification.error({
              message: 'ERROR',
              description: res?.error.message
            })
          }

          if (res?.data) {
            setUserCognito(res?.data)

            setLoadingDDAccountTab(true)
            const accountBalancePromise = E6Services.getE6AccountBalance()

            const fromDate = '2021-02-01'
            const endDate = moment(new Date()).format('YYYY-MM-DD')

            const transactionsPromise = E6Services.getE6TransactionList(fromDate, endDate)

            const [res1, res2] = await Promise.all([accountBalancePromise, transactionsPromise])
            setLoadingDDAccountTab(false)
            setDDAccountBalance(res1.data)

            if (!Array.isArray(res2?.data)) return

            const dataUMBFormatted = res2.data.map((item, index) => {
              return Object.assign(item, { key: ++index })
            })
            setDDAccountTransactionList(dataUMBFormatted)
          }
        } catch (error) {
          setLoadingCognitoUser(false)
          setLoadingDDAccountTab(false)
        }
      }
      fetchDataCognito()
    }
  }, [userId])

  const getClientSource = (bankId: string) => {
    switch (bankId) {
      case '103002691': {
        return 'First Fidelity Bank'
      }
      case '311079306': {
        return 'Credit Union of Texas'
      }
      case '051404464': {
        return 'First Bank and Trust Company'
      }
      case '103100881': {
        return 'First United Bank'
      }
      default: {
        return 'D2C'
      }
    }
  }

  const RenderDescription = () => {
    const generalInfo: Array<Description> = [
      { label: 'Client Source', children: getClientSource(userCognito?.bankId) },
      { label: 'First Name', children: userCognito.name },
      { label: 'Last Name', children: userCognito.familyName },
      { label: 'Email', children: userCognito.email },
      {
        label: 'Onboarded Date',
        children: userCognito.createdDate
          ? moment(userCognito.createdDate).format('YYYY-MM-DD')
          : ''
      },
      { label: 'User Status', children: userCognito?.payload?.['custom:e6_status'] },
      { label: 'Phone', children: userCognito.phoneNumber },
      {
        label: 'App Status',
        children: (
          <Popconfirm
            title={
              user.enabled
                ? 'Are you sure to disable this Gemini account?'
                : 'Are you sure to enable this Gemini account?'
            }
            onConfirm={handleDisableAppStatus}
            visible={user.key === disableKey}
            okButtonProps={{ loading: user.key === loadingKey }}
            onCancel={handleCancelConfirmAppStatus}
          >
            <Switch
              checkedChildren="Enable"
              unCheckedChildren="Disable"
              defaultChecked={user.enabled}
              disabled={user.key === disableKey}
              onClick={handleSwitchClickAppStatus}
            />
          </Popconfirm>
        )
      }
    ]

    const infoDetailTabs = {
      dDAccount: [
        { label: 'E6 ID', children: dDAccountBalance?.accountNumber },
        {
          label: 'Available Balance',
          children: formatAmount(Number(dDAccountBalance?.availableBalance || 0))
        },
        {
          label: 'Trading Credit',
          children: formatAmount(Number(dDAccountBalance?.availableTradingCredit || 0))
        },
        {
          label: 'Gift Card',
          children: formatAmount(Number(dDAccountBalance?.availableGifCard || 0))
        }
      ],
      robo: [
        { label: 'Robo A/C Number', children: roboAccountBalance?.accountNo },
        {
          label: 'Portfolio Value',
          children: formatAmount(Number(roboAccountBalance?.equity?.equityValue || 0))
        },
        {
          label: 'Cash',
          children: formatAmount(Number(roboAccountBalance?.cash?.cashBalance || 0))
        },
        {
          label: 'Robo Balance',
          children: formatAmount(Number(roboAccountBalance?.totalBalance || 0))
        }
      ],
      selfDirected: [
        {
          label: 'Self-Elected A/C Number',
          children: selfDirectedAccountBalance?.accountNo
        },
        {
          label: 'Portfolio Value',
          children: formatAmount(Number(selfDirectedAccountBalance?.equity?.equityValue || 0))
        },
        {
          label: 'Cash',
          children: formatAmount(Number(selfDirectedAccountBalance?.cash?.cashBalance || 0))
        },
        {
          label: 'Self-Elected Balance',
          children: formatAmount(Number(selfDirectedAccountBalance?.equity?.equityValue || 0))
        }
      ],
      crypto: [
        {
          label: 'A/C Number',
          children: geminiAccountBalance?.gemini_account_name
        },
        {
          label: 'Current Balance',
          children: formatAmount(Number(geminiAccountBalance?.gemini_current_balance || 0))
        }
      ],
      preciousMetal: [
        { label: 'Account Code', children: gbiAccountHolding[0]?.AccountCode },
        {
          label: 'Total Portfolio',
          children: formatAmount(
            gbiAccountHolding.reduce((total: number, item: any) => total + item.Valuation, 0)
          )
        }
      ]
    }

    return (
      <Descriptions title="User Info" column={4}>
        {generalInfo.map((item) => (
          <Descriptions.Item key={item.label} label={item.label}>
            {item.children}
          </Descriptions.Item>
        ))}

        {selectTabs === TabPaneKey.DD_ACCOUNT && (
          <>
            {infoDetailTabs.dDAccount.map((item) => (
              <Descriptions.Item key={item.label} label={item.label}>
                {item.children}
              </Descriptions.Item>
            ))}
          </>
        )}

        {selectTabs === TabPaneKey.ROBO && (
          <>
            {infoDetailTabs.robo.map((item) => (
              <Descriptions.Item key={item.label} label={item.label}>
                {item.children}
              </Descriptions.Item>
            ))}
          </>
        )}

        {selectTabs === TabPaneKey.SELF_ELECTED && (
          <>
            {infoDetailTabs.selfDirected.map((item) => (
              <Descriptions.Item key={item.label} label={item.label}>
                {item.children}
              </Descriptions.Item>
            ))}
          </>
        )}

        {selectTabs === TabPaneKey.CRYPTO && (
          <>
            {infoDetailTabs.crypto.map((item) => (
              <Descriptions.Item key={item.label} label={item.label}>
                {item.children}
              </Descriptions.Item>
            ))}
          </>
        )}

        {selectTabs === TabPaneKey.PRECIOUS_METAL && (
          <>
            {infoDetailTabs.preciousMetal.map((item) => (
              <Descriptions.Item key={item.label} label={item.label}>
                {item.children}
              </Descriptions.Item>
            ))}

            <br />
            <br />

            {gbiAccountHolding.map((hoding: any) => (
              <Descriptions.Item key={hoding?.SymbolCode}>
                <Descriptions column={1}>
                  <Descriptions.Item label={`Fractional ${formatSymbolCode(hoding?.SymbolCode)}`}>
                    {}
                  </Descriptions.Item>
                  <Descriptions.Item label="Valuation">
                    {formatAmount(hoding?.Valuation)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Available Quantity">
                    {`${hoding?.AvailableQuantity} oz`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Settled Quantity">
                    {`${hoding?.SettledQuantity} oz`}
                  </Descriptions.Item>
                </Descriptions>
              </Descriptions.Item>
            ))}
          </>
        )}
      </Descriptions>
    )
  }

  const fetchDataRobo = async () => {
    try {
      setLoadingRoboTab(true)
      const accountBalancePromise = RoboServices.getApexRoboAccountBalance()
      const transactionPromise = RoboServices.getApexRoboTransactionList()

      const [res1, res2] = await Promise.all([accountBalancePromise, transactionPromise])
      setLoadingRoboTab(false)
      setRoboAccountBalance(res1.data)

      if (!Array.isArray(res2?.data)) return

      const dataRBFormatted = res2?.data
        .map((item, index) => Object.assign(item, { key: ++index }))
        .sort((item1, item2) => Date.parse(item2.tranWhen) - Date.parse(item1.tranWhen))

      setRoboTransactionList(dataRBFormatted)
    } catch (error) {
      setLoadingRoboTab(false)
    }
  }

  const fetchDataSelf = async () => {
    try {
      setLoadingSelfTab(true)
      const accountBalancePromise = SelfServices.getApexSelfAccountBalance()
      const transactionPromise = SelfServices.getApexSelfTransactionList()

      const [res1, res2] = await Promise.all([accountBalancePromise, transactionPromise])
      setLoadingSelfTab(false)
      setSelfDirectedAccountBalance(res1.data)

      if (!Array.isArray(res2?.data)) return

      const dataSDFormatted = res2?.data
        .map((item, index) => Object.assign(item, { key: ++index }))
        .sort((item1, item2) => Date.parse(item2.tranWhen) - Date.parse(item1.tranWhen))

      setSelfTransactionList(dataSDFormatted)
    } catch (error) {
      setLoadingSelfTab(false)
    }
  }

  const fetchDataMetal = async () => {
    try {
      setLoadingPreciousMetalTab(true)
      const holdingPromise = MetalServices.getGBIAccountHolding()
      const transactionsPromise = MetalServices.getTransactionList()

      const [res1, res2] = await Promise.all([holdingPromise, transactionsPromise])
      setLoadingPreciousMetalTab(false)

      if (res1?.error || res2?.error) {
        return notification.error({
          message: 'ERROR',
          description: res1?.error?.message || res2?.error?.message
        })
      }

      if (!Array.isArray(res1?.data) || !Array.isArray(res2?.data)) return

      setGbiAccountHolding(res1.data)

      const dataGBIFormated = res2.data.map((item, index: number) => {
        if (item?.StatusType === 'SecureStorageFee') {
          return {
            ...item,
            Side: '',
            Quantity: '',
            key: ++index
          }
        }

        return {
          ...item,
          Side: `${item?.FractionalTrade?.QuoteRequest?.SideType || ''} ${formatSymbolCode(
            item?.FractionalTrade?.SymbolCode || ''
          )}`,
          Quantity: item?.FractionalTrade?.QuantityExecuted
            ? `${item?.FractionalTrade?.QuantityExecuted} oz`
            : '',
          key: ++index
        }
      })
      setGBITransactionList(dataGBIFormated)
    } catch (error) {
      setLoadingPreciousMetalTab(false)
    }
  }

  const fetchDataLinkedBank = async () => {
    try {
      setLoadingLinkedBankTab(true)
      const response = await UserServices.getLinkedBank()
      setLoadingLinkedBankTab(false)

      if (response.error) {
        return notification.error({
          message: 'ERROR',
          description: response?.error?.message
        })
      }

      if (!Array.isArray(response.data)) return

      const dataLinkedBankFormated = response.data.map((item, index) => {
        return Object.assign(item, { key: ++index })
      })
      setDataLinkedBank(dataLinkedBankFormated)
    } catch (error) {
      setLoadingLinkedBankTab(false)
    }
  }

  const fetchDataCrypto = async () => {
    try {
      setLoadingCryptoTab(true)
      const accountBalancePromise = CryptoServices.getCryptoAccountBalance()
      const transactionPromise = CryptoServices.getCryptoTransactionList()

      const [res1, res2] = await Promise.all([accountBalancePromise, transactionPromise])
      setLoadingCryptoTab(false)

      if (res1?.error || res2?.error) {
        return notification.error({
          message: 'ERROR',
          description: res1?.error?.message || res2?.error?.message
        })
      }
      setGeminiAccountBalance(res1.data)

      if (!Array.isArray(res2?.data)) return

      const dataGEMFormated = res2.data.map((item, index) => {
        return Object.assign(item, { key: ++index })
      })
      setCryptoTransactionList(dataGEMFormated)
    } catch (error) {
      setLoadingCryptoTab(false)
    }
  }

  const handleTabClick = async (key: string) => {
    setSelectTabs(key)

    switch (key) {
      case TabPaneKey.ROBO: {
        fetchDataRobo()
        break
      }
      case TabPaneKey.SELF_ELECTED: {
        fetchDataSelf()
        break
      }
      case TabPaneKey.CRYPTO: {
        fetchDataCrypto()
        break
      }
      case TabPaneKey.PRECIOUS_METAL: {
        fetchDataMetal()
        break
      }
      case TabPaneKey.LINKED_BANKS: {
        fetchDataLinkedBank()
        break
      }
    }
  }

  const tabPaneItems = [
    {
      key: TabPaneKey.DD_ACCOUNT,
      label: 'DD Account (E6)',
      children: (
        <>
          {loadingCognitoUser || loadingDDAccountTab ? (
            <Skeleton active={loadingCognitoUser || loadingDDAccountTab} />
          ) : (
            RenderDescription()
          )}
          <Table
            dataSource={dDAccountTransactionList}
            columns={columns}
            loading={loadingDDAccountTab}
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
          />
        </>
      )
    },
    {
      key: TabPaneKey.ROBO,
      label: 'Robo (Apex)',
      children: (
        <>
          {loadingCognitoUser || loadingRoboTab ? (
            <Skeleton active={loadingCognitoUser || loadingRoboTab} />
          ) : (
            RenderDescription()
          )}
          <Table
            dataSource={roboTransactionList}
            columns={columnsDW}
            loading={loadingRoboTab}
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
          />
        </>
      )
    },
    {
      key: TabPaneKey.SELF_ELECTED,
      label: 'Self-Elected (Apex)',
      children: (
        <>
          {loadingCognitoUser || loadingSelfTab ? (
            <Skeleton active={loadingCognitoUser || loadingSelfTab} />
          ) : (
            RenderDescription()
          )}
          <Table
            dataSource={selfTransactionList}
            columns={columnsDW}
            loading={loadingSelfTab}
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
          />
        </>
      )
    },
    {
      key: TabPaneKey.CRYPTO,
      label: 'Crypto (GEM)',
      children: (
        <>
          {loadingCognitoUser || loadingCryptoTab ? (
            <Skeleton active={loadingCognitoUser || loadingCryptoTab} />
          ) : (
            RenderDescription()
          )}
          <Table
            dataSource={cryptoTransactionList}
            columns={columnsGEM}
            loading={loadingCryptoTab}
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
          />
        </>
      )
    },
    {
      key: TabPaneKey.PRECIOUS_METAL,
      label: 'Precious Metal (GBI)',
      children: (
        <>
          {loadingCognitoUser || loadingPreciousMetalTab ? (
            <Skeleton active={loadingCognitoUser || loadingPreciousMetalTab} />
          ) : (
            RenderDescription()
          )}
          <Table
            dataSource={gbiTransactionList}
            columns={columnsGBI}
            loading={loadingPreciousMetalTab}
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
          />
        </>
      )
    },
    {
      key: TabPaneKey.LINKED_BANKS,
      label: 'Linked Banks',
      children: (
        <>
          {loadingCognitoUser || loadingLinkedBankTab ? (
            <Skeleton active={loadingCognitoUser || loadingLinkedBankTab} />
          ) : (
            RenderDescription()
          )}
          <Table
            dataSource={dataLinkedBank}
            columns={columnsLinkedBanks}
            loading={loadingLinkedBankTab}
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
          />
        </>
      )
    },
    {
      key: TabPaneKey.KYC,
      label: 'KYC',
      children: (
        <>
          {loadingCognitoUser ? (
            <Skeleton active={loadingCognitoUser} />
          ) : (
            <KYCTabContent userCognito={userCognito} />
          )}
        </>
      )
    }
  ]

  return (
    <Modal
      title={title ? title : 'Last 6 transactions'}
      visible={isVisible}
      destroyOnClose={true}
      closable={true}
      onCancel={() => {
        onClose?.()
        setSelectTabs(TabPaneKey.DD_ACCOUNT)
      }}
      footer={null}
      width="90%"
    >
      <Tabs
        defaultActiveKey={TabPaneKey.DD_ACCOUNT}
        activeKey={selectTabs}
        type="card"
        size="large"
        items={tabPaneItems}
        onChange={handleTabClick}
      />
    </Modal>
  )
}

export default TransactionModal
