import { Table, Switch, Input, DatePicker } from 'antd'
import React from 'react'
import { connect } from 'react-redux'
import styles from './styles.module.css'
import PromoServices from 'src/api/PromoServices'
import { formatAmount, formatDay } from 'src/utils/format'
import { CloseOutlined, SearchOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

export interface UserPromo {
  user_name: string
  full_name: string
  phone_number: string
  email: string
  onboard_date: string | null
  customer_type: string
  funded_amount: number
  funded_date: string | null
  deposit_within_days: string
  trade_first_date: string | null
  trading_requirement: string
  credit_issued_date: string | null
  credit_expiry_date: string | null
}

export interface PromoCampaignState {
  users: UserPromo[]
  loading: boolean
  searchText: string
  filteredInfo: any
  errorMessage: string
  page: number
  limit: number
  total: number
  searching: boolean
  user_name: string
  customer_type: string
  customerTypeFilter: any
  deposit_within_days: string
  depositWithinFilter: any
  trading_requirement: string
  tradingRequirementFilter: any
  funded_amount_sort: string
  onboard_date: string | null
  funded_date: string | null
  trade_first_date: string | null
  credit_issued_date: string | null
  credit_expiry_date: string | null
  clickCount: number
}


class PromoCampaign extends React.PureComponent<any, PromoCampaignState> {
  constructor(props: any) {
    super(props)
    this.state = {
      users: [],
      loading: true,
      searchText: '',
      filteredInfo: {},
      errorMessage: '',
      page: 1,
      limit: 10,
      total: 0,
      searching: false,
      user_name: '',
      customer_type: '',
      customerTypeFilter: '',
      deposit_within_days: '',
      depositWithinFilter: '',
      trading_requirement: '',
      tradingRequirementFilter: '',
      funded_amount_sort: '',
      onboard_date: '',
      funded_date: '',
      trade_first_date: '',
      credit_issued_date: '',
      credit_expiry_date: '',
      clickCount: 0
    }
  }

  componentDidMount() {
    this.fetchDataPromo()
  }

  fetchDataPromo = async () => {
    dayjs.extend(utc)
    const formatPromoDay = 'DD-MMM-YYYY'
    const {
      page,
      limit,
      user_name,
      customer_type,
      deposit_within_days,
      trading_requirement,
      funded_amount_sort,
      onboard_date,
      funded_date,
      trade_first_date,
      credit_issued_date,
      credit_expiry_date
    } = this.state
    try {
      this.setState({ loading: true })
      const res = await PromoServices.getPromoList(
        page,
        limit,
        user_name,
        customer_type,
        deposit_within_days,
        trading_requirement,
        funded_amount_sort,
        onboard_date,
        funded_date,
        trade_first_date,
        credit_issued_date,
        credit_expiry_date
      )
      if (res?.data) {
        const { data, total } = res.data
        const formatDate = (date: string | null): string => {
          return date ? dayjs.utc(date).format(formatPromoDay) : ''
        }
        const formattedData = data.map((item: UserPromo, index: number) => ({
          key: index,
          ...item,
          createdDateOnboard: formatDate(item.onboard_date),
          createdDateFunded: formatDate(item.funded_date),
          createdDateTradeFirst: formatDate(item.trade_first_date),
          createdDateCreditIssued: formatDate(item.credit_issued_date),
          createdDateCreditExpiry: formatDate(item.credit_expiry_date),
          amountFunded: item.funded_amount ? formatAmount(item.funded_amount) : ` $0.00`
        }))
        this.setState({
          users: formattedData,
          loading: false,
          total
        })
      }
    } catch (error: any) {
      console.error('Error fetching promo data:', error)
      this.setState({
        loading: false,
        errorMessage: error.message
      })
    }
  }

  handleChange = (filters: any) => {
    this.setState({
      filteredInfo: filters
    })
  }

  handlePaginationChange = (page: number, pageSize?: number) => {
    this.setState({ page, limit: pageSize || this.state.limit }, () => {
      this.fetchDataPromo()
    })
  }
  handlePageSizeChange = (size: number) => {
    this.setState({ limit: size }, () => {
      this.fetchDataPromo()
    })
  }

  handleClearInput = () => {
    this.setState({ user_name: '', searchText: '' }, () => {
      this.fetchDataPromo()
    })
  }

  handleSearch = async (searchText: string) => {
    this.setState({ user_name: searchText }, () => {
      this.fetchDataPromo()
    })
  }

  handleFieldChange = (fieldName: keyof PromoCampaignState, value: string) => {
    this.setState((prevState) => ({
      ...prevState,
      [fieldName]: value
    }))
  }

  handleFieldClear = (fieldName: keyof PromoCampaignState) => {
    this.setState((prevState) => ({
      ...prevState,
      [fieldName]: ''
    }))
  }

  handleDateChange = (fieldDate: keyof PromoCampaignState, date: string | null) => {
    const formattedDate = date !== null ? formatDay(date, 'YYYY-MM-DD') : ''
    this.setState((prevState) => ({
      ...prevState,
      [fieldDate]: formattedDate
    }), () => {
      this.fetchDataPromo()
    })
  }


  handleFundedAmountSortUp = () => {
    this.setState({ funded_amount_sort: 'DESC' }, () => {
      this.fetchDataPromo()
    })
  }

  handleFundedAmountSortDown = () => {
    this.setState({ funded_amount_sort: 'ASC' }, () => {
      this.fetchDataPromo()
    })
  }

  handleFundedAmountSortClear = () => {
    this.setState({ funded_amount_sort: '', clickCount: -1 }, () => {
      this.fetchDataPromo()
    })
  }

  handleHeaderCellClick = () => {
    this.setState(prevState => ({
      clickCount: prevState.clickCount + 1
    }))
  }

  render() {
    const { users, loading, total, page, limit, searchText } = this.state
    const columns = [
      {
        title: (
          <div>
            <Input
              placeholder="Username"
              value={searchText}
              onChange={(e) => this.setState({ searchText: e.target.value })}
              style={{ width: 310, marginBottom: 5, display: 'flex' }}
              suffix={
                searchText !== '' ? (
                  <div>
                    <CloseOutlined
                      style={{ color: 'rgba(0, 0, 0, 0.25)', cursor: 'pointer', marginRight: 8 }}
                      onClick={this.handleClearInput}
                    />
                    <SearchOutlined
                      style={{ color: 'rgba(0, 0, 0, 0.25)', cursor: 'pointer' }}
                      onClick={() => this.handleSearch(searchText)}
                    />
                  </div>
                ) : (
                  <SearchOutlined
                    style={{ color: 'rgba(0, 0, 0, 0.25)', cursor: 'pointer' }}
                    onClick={() => this.handleSearch(searchText)}
                  />
                )
              }
            />
          </div>
        ),
        dataIndex: 'user_name',
        key: 'user_name',
        ellipsis: true,
        width: '18%',
        onFilter: (value: any, record: any) => record.user_name.toLowerCase().includes(value.toLowerCase())
      },
      {
        title: 'Customer Type',
        dataIndex: 'customer_type',
        key: 'customer_type',
        width: '8%',
        filters: [
          { text: 'New', value: 'New' },
          { text: 'Existing', value: 'Existing' }
        ],
        filterMultiple: false,
        render: (text: string) => <div className={styles.alignmentFormat}>{text}</div>,
        onFilter: (value: any) => {
          if (value === '') {
            this.handleFieldClear('customer_type')
          } else {
            this.handleFieldChange('customer_type', value)
          }
          return true
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          const { customerTypeFilter } = this.state
          if (!visible && !customerTypeFilter.customer_type) {
            this.handleFieldClear('customer_type')
          }
        }
      },

      {
        title: (
          <div>
            <DatePicker
              format={'DD-MMM-YYYY'}
              placeholder="Onboarded Date"
              style={{ width: 150 }}
              onChange={(date: any) => this.handleDateChange('onboard_date', date)}
            />
          </div>
        ),
        dataIndex: "createdDateOnboard",
        key: "createdDateOnboard",
        ellipsis: true,
        width: '10%',
        render: (text: string) =>
          <div className={styles.alignmentFormat}>{text}</div>
      },
      {
        title: 'Amount Funded',
        dataIndex: "amountFunded",
        key: "amountFunded",
        sorter: true,
        width: '8%',
        onHeaderCell: () => {
          return {
            onClick: () => {
              const { clickCount } = this.state
              if (clickCount === 0) {
                this.handleFundedAmountSortUp()
              } else if (clickCount === 1) {
                this.handleFundedAmountSortDown()
              } else if (clickCount === 2) {
                this.handleFundedAmountSortClear()
              } else {
                this.handleFundedAmountSortClear()
              }
              this.handleHeaderCellClick()
            }
          }
        },
        render: (text: string) =>
          <div style={{ marginLeft: '20%' }}> {text}</div >
      },
      {
        title: (
          <div>
            <DatePicker
              format={'DD-MMM-YYYY'}
              placeholder='Funded Date'
              style={{ width: 150 }}
              onChange={(date: any) => this.handleDateChange('funded_date', date)}
            />
          </div>
        ),
        dataIndex: "createdDateFunded",
        key: "createdDateFunded",
        ellipsis: true,
        width: '10%',
        render: (text: string) =>
          <div className={styles.alignmentFormat}>{text}</div>
      },
      {
        title: 'Deposit Within 14 days?',
        dataIndex: 'deposit_within_days',
        key: 'deposit_within_days',
        width: '11%',
        render: (text: string, record: UserPromo) => {
          return (
            <div style={{ marginLeft: '25%' }}>
              <Switch
                style={{ opacity: 1 }}
                checkedChildren="Yes"
                unCheckedChildren="No"
                checked={record.deposit_within_days === 'YES' ? true : false}
                disabled={true}
              />
            </div>
          )
        },
        filters: [
          { text: 'Yes', value: 'Yes' },
          { text: 'No', value: 'No' }
        ],
        filterMultiple: false,
        onFilter: (value: any) => {
          if (value === '') {
            this.handleFieldClear('deposit_within_days')
          } else {
            this.handleFieldChange('deposit_within_days', value)
          }
          return true
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          const { depositWithinFilter } = this.state
          if (!visible && !depositWithinFilter.deposit_within_days) {
            this.handleFieldClear('deposit_within_days')
          }
        }
      },
      {
        title: (
          <div>
            <DatePicker
              format={'DD-MMM-YYYY'}
              placeholder='First Trade Date'
              style={{ width: 150 }}
              onChange={(date: any) => this.handleDateChange('trade_first_date', date)}
            />
          </div>
        ),
        dataIndex: "createdDateTradeFirst",
        key: "createdDateTradeFirst",
        ellipsis: true,
        width: '10%',
        render: (text: string) =>
          <div className={styles.alignmentFormat}>{text}</div>
      },
      {
        title: 'Trading Requirement?',
        dataIndex: 'trading_requirement',
        key: 'trading_requirement',
        width: '10%',
        render: (text: string, record: UserPromo) => {
          return (
            <div style={{ marginLeft: '25%' }}>
              <Switch
                style={{ opacity: 1 }}
                checkedChildren="Yes"
                unCheckedChildren="No"
                checked={record.trading_requirement === 'YES' ? true : false}
                disabled={true}
              />
            </div >
          )
        },
        filters: [
          { text: 'Yes', value: 'Yes' },
          { text: 'No', value: 'No' }
        ],
        filterMultiple: false,
        onFilter: (value: any) => {
          if (value === '') {
            this.handleFieldClear('trading_requirement')
          } else {
            this.handleFieldChange('trading_requirement', value)
          }
          return true
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          const { tradingRequirementFilter } = this.state
          if (!visible && !tradingRequirementFilter.trading_requirement) {
            this.handleFieldClear('trading_requirement')
          }
        }
      },
      {
        title: (
          <div>
            <DatePicker
              format={'DD-MMM-YYYY'}
              placeholder='Credit Issued Date'
              style={{ width: 160 }}
              onChange={(date: any) => this.handleDateChange('credit_issued_date', date)}
            />
          </div>
        ),
        dataIndex: "createdDateCreditIssued",
        key: "createdDateCreditIssued",
        ellipsis: true,
        width: '10%',
        render: (text: string) =>
          <div className={styles.alignmentFormat}>{text}</div>
      },
      {
        title: (
          <div>
            <DatePicker
              format={'DD-MMM-YYYY'}
              placeholder='Credit Expiry Date'
              style={{ width: 155 }}
              onChange={(date: any) => this.handleDateChange('credit_expiry_date', date)}
            />
          </div>
        ),
        dataIndex: "createdDateCreditExpiry",
        key: "createdDateCreditExpiry",
        ellipsis: true,
        width: '10%',
        render: (text: string) =>
          <div className={styles.alignmentFormat}>{text}</div>
      }
    ]

    return (
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <div>
            <h1>FFB Promo Campaign</h1>
          </div>
          <Table
            dataSource={users}
            columns={columns}
            loading={loading}
            onChange={(filters) => this.handleChange(filters)}
            scroll={{ x: 2000 }}
            pagination={{
              total,
              current: page,
              pageSize: limit,
              onChange: this.handlePaginationChange,
              onShowSizeChange: this.handlePageSizeChange,
              showSizeChanger: true
            }}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: { listUserReducer: any }) => {
  return {
    listUserReducer: state.listUserReducer
  }
}

export default connect(mapStateToProps)(PromoCampaign)
