import HTTPRequest from './index'
import { AxiosResponse } from 'axios'
import { APIs } from '../constants'

const ReconciliationServices = {
  getReportACHAFT: (
    page: number,
    limit: number,
    searchBy: string | null,
    search: string | null,
    sortBy: string | null,
    sort: string | null,
    fromDate: string
  ): Promise<AxiosResponse['data']> => {
    const params = {
      page,
      limit,
      searchBy,
      search,
      sortBy,
      sort,
      fromDate
    }

    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(
        ([_, value]) => value !== undefined && value !== null && value !== ''
      )
    )
    return HTTPRequest.get(APIs.GET_REPORT_ACH_AFT, { params: filteredParams })
  },
  getReportPYC: (
    page: number,
    limit: number,
    searchBy: string | null,
    search: string | null,
    sortBy: string | null,
    sort: string | null
  ): Promise<AxiosResponse['data']> => {
    const params = {
      page,
      limit,
      searchBy,
      search,
      sortBy,
      sort
    }

    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(
        ([_, value]) => value !== undefined && value !== null && value !== ''
      )
    )
    return HTTPRequest.get(APIs.GET_REPORT_PYC, { params: filteredParams })
  }
}

export default ReconciliationServices
