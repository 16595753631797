import { navigate } from '@reach/router'
import { Button, Input, message, notification, Typography } from 'antd'
import React from 'react'
import HTTPRequest from '../../api'
import ThreeDotsWave from './ThreeDotsWave'

interface SecurityCodeState {
  loading: boolean
  value: string
  loadingButton: boolean
}

const { Title, Text } = Typography

export default class SecurityCode extends React.Component<any, SecurityCodeState> {
  constructor(props: any) {
    super(props)
    this.state = {
      loading: true,
      value: '',
      loadingButton: false
    }
  }

  async componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false })
    }, 2500)
  }

  onChange = (e: any): void => {
    const { value } = e.target
    const reg = /^-?\d*(\.\d*)?$/
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      this.setState({ value })
      return
    }
  }

  HandleClick = async (): Promise<void> => {
    this.setState({ loadingButton: true })
    const { Username, Password } = this.props.location.state
    try {
      const res: any = await HTTPRequest.post('/auth/login', {
        name: Username,
        password: Password,
        userCode: this.state.value
      })
      this.setState({ loadingButton: false })
      if (res.accessToken && res.accessToken.jwtToken) {
        await sessionStorage.setItem('jwtToken', res.accessToken.jwtToken)
        await sessionStorage.setItem('username', Username)
        navigate('/home/dashboard', { replace: true })
      } else {
        message.error(res.message)
      }
    } catch (error: any) {
      notification['error']({
        message: 'ERROR',
        description: error.error.message
      })
      this.setState({ loadingButton: false })
    }
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '0 auto',
          height: '100vh',
          maxWidth: '500px'
        }}
      >
        {this.state.loading ? (
          <ThreeDotsWave />
        ) : (
          <div style={{ flexDirection: 'column', display: 'flex' }}>
            <Title style={{ color: '#1E90FF', fontSize: 50, alignSelf: 'center' }}>
              Security Code
            </Title>

            <Text style={{ fontSize: 22, textAlign: 'center' }}>
              Please enter the 2-factor verification code provided by Google Application
            </Text>

            <Input
              value={this.state.value}
              {...this.props}
              maxLength={6}
              onChange={this.onChange}
              style={{ width: '65%', alignSelf: 'center', marginTop: '6%' }}
            />

            <Button
              onClick={() => this.HandleClick()}
              style={{ marginTop: '6%', width: '25%', alignSelf: 'center' }}
              type="primary"
              htmlType="submit"
              loading={this.state.loadingButton}
            >
              Continue
            </Button>
          </div>
        )}
      </div>
    )
  }
}
