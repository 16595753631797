import dayjs from 'dayjs'
import { SymbolCode } from '../constants'

export const formatDay = (day: string, format = 'DD-MMM-YYYY'): string => {
  return dayjs(day).isValid() ? dayjs(day).format(format) : ''
}

export const formatAmount = (amount: number): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2
  }).format(amount)
}

export const formatSymbolCode = (symbolCode: string): string => {
  switch (symbolCode) {
    case SymbolCode.PLATINUM: {
      return 'Platinum'
    }
    case SymbolCode.GOLD: {
      return 'Gold'
    }
    case SymbolCode.SILVER: {
      return 'Silver'
    }
    default: {
      return ''
    }
  }
}

export const capitalizeString = (text: string) => {
  const texts = text.split(' ')
  const textsUpper = []

  for (const n of texts) {
    textsUpper.push(n.replace(n[0], n[0].toUpperCase()))
  }
  return textsUpper.join(' ')
}

export const convertTitleCase = (title: string, split: string) => {
  if (title === '') return ''
  const capitalize = (str: string) => str[0].toUpperCase() + str.slice(1)

  const exceptions = ['a', 'an', 'of', 'and', 'the', 'but', 'or', 'on', 'in', 'with', 'for', 'at']

  const titleCase = title
    .toLowerCase()
    .split(split)
    .map((word) => (exceptions.includes(word) ? word : capitalize(word)))
    .join(' ')

  return capitalize(titleCase)
}

export function capitalizeCamelCase(str: string) {
  if (str === '') return
  const words = str.split(/(?=[A-Z])/)
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  const capitalizedString = capitalizedWords.join(' ')

  return capitalizedString
}
