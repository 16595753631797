import { DatePicker, Input, Select, Table } from 'antd'
import React from 'react'
import { connect } from 'react-redux'
import styles from './styles.module.css'
import { formatAmount, formatDay } from 'src/utils/format'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import ReconciliationServices from 'src/api/ReconciliationService'
import moment from 'moment'

const { Option } = Select

export interface ACHReport {
  UNISeqID: string
  UNIProg: string
  UNIAmt: number
  FFBAmt: number
  e6amt: number
  FFBType: string
  UNICreate: string
  UNIprocessed: string
  Processtime: string
  FFBUTCCreate: string
  e6txid: string
  UNIFile: string
  FFBfile: string
  e6file: string
  fromDate: string
}

export interface ACHReportState {
  reports: ACHReport[]
  loading: boolean
  search: string
  searchBy: string
  sort: string
  sortBy: string
  errorMessage: string
  page: number
  limit: number
  total: number
  searching: boolean
  clickCount: number
  currentSort: string
  fromDate: string
}

class ACHRecReport extends React.PureComponent<any, ACHReportState> {
  constructor(props: any) {
    super(props)
    this.state = {
      reports: [],
      loading: true,
      errorMessage: '',
      page: 1,
      limit: 10,
      total: 0,
      searching: false,
      search: '',
      searchBy: '',
      sort: '',
      sortBy: '',
      clickCount: 0,
      currentSort: '',
      fromDate: moment(new Date()).subtract(1, 'M').format('YYYY-MM-DD'),
    }
  }

  componentDidMount() {
    this.fetchDataReport()
  }

  fetchDataReport = async () => {
    dayjs.extend(utc)
    const formatDay = 'DD-MMM-YYYY'
    const { page, limit, search, searchBy, sort, sortBy, fromDate } = this.state
    try {
      this.setState({ loading: true })
      const res = await ReconciliationServices.getReportACHAFT(
        page,
        limit,
        searchBy,
        search,
        sortBy,
        sort,
        fromDate
      )
      if (res?.data) {
        const { data, total } = res.data
        const formatDate = (date: string | null): string => {
          return date ? dayjs.utc(date).format(formatDay) : ''
        }
        const formattedData = data.map((item: ACHReport, index: number) => ({
          key: index,
          ...item,
          formatUTCCreate: formatDate(item.FFBUTCCreate),
          formatUNICreate: formatDate(item.UNICreate),
          formatUNIprocessed: formatDate(item.UNIprocessed),
          amountUNI: item.UNIAmt ? formatAmount(item.UNIAmt) : '$0.00',
          amountFFB: item.FFBAmt ? formatAmount(item.FFBAmt) : '$0.00',
          amountE6: item.e6amt ? formatAmount(item.e6amt) : '$0.00'
        }))
        this.setState({
          reports: formattedData,
          loading: false,
          total
        })
      }
    } catch (error: any) {
      console.error('Error fetching ACH report data:', error)
      this.setState({
        loading: false,
        errorMessage: error.message
      })
    }
  }

  handlePaginationChange = (page: number, pageSize?: number) => {
    this.setState({ page, limit: pageSize || this.state.limit }, () => {
      this.fetchDataReport()
    })
  }
  handlePageSizeChange = (size: number) => {
    this.setState({ limit: size }, () => {
      this.fetchDataReport()
    })
  }

  handleSearchSelectChange = (value: any) => {
    this.setState({
      searchBy: value
    })
  }

  handleClearInput = () => {
    this.setState({ search: '', searchBy: '' }, () => {
      this.fetchDataReport()
    })
  }

  handleSearch = async (event: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
    const value = (event.target as HTMLInputElement).value

    if (this.state.searchBy) {
      this.setState({ search: value, page: 1 }, () => {
        this.fetchDataReport()
      })
    } else {
      this.setState({ searchBy: 'program_id', search: value, page: 1 }, () => {
        this.fetchDataReport()
      })
    }
  }

  handleFieldChange = (fieldName: keyof ACHReportState, value: string) => {
    this.setState((prevState) => ({
      ...prevState,
      [fieldName]: value
    }))
  }

  handleFieldClear = (fieldName: keyof ACHReportState) => {
    this.setState((prevState) => ({
      ...prevState,
      [fieldName]: ''
    }))
  }

  handleDateChange = (fieldDate: keyof ACHReportState, date: string | null) => {
    const formattedDate =
      date !== null
        ? formatDay(date, 'YYYY-MM-DD')
        : formatDay(moment(new Date()).subtract(1, 'M') as any, 'YYYY-MM-DD')
    this.setState(
      (prevState) => ({
        ...prevState,
        [fieldDate]: formattedDate
      }),
      () => {
        this.fetchDataReport()
      }
    )
  }

  handleFundedAmountSortUp = (sortBy: string) => {
    this.setState({ sortBy, sort: 'DESC', currentSort: sortBy, clickCount: 0 }, () => {
      this.fetchDataReport()
    })
  }

  handleFundedAmountSortDown = (sortBy: string) => {
    this.setState({ sortBy, sort: 'ASC' }, () => {
      this.fetchDataReport()
    })
  }

  handleFundedAmountSortClear = () => {
    this.setState({ sortBy: '', sort: '', clickCount: -1 }, () => {
      this.fetchDataReport()
    })
  }

  handleHeaderCellClick = () => {
    this.setState((prevState) => ({
      clickCount: prevState.clickCount + 1
    }))
  }

  render() {
    const { reports, loading, total, page, limit } = this.state
    const columns = [
      {
        title: 'ID',
        dataIndex: 'UNISeqID',
        key: 'UNISeqID',
        sorter: true,
        width: '6%',
        onHeaderCell: () => {
          return {
            onClick: () => {
              const { clickCount, currentSort } = this.state
              if (clickCount === 0 || currentSort !== 'UNISeqID') {
                this.handleFundedAmountSortUp('UNISeqID')
              } else if (clickCount === 1) {
                this.handleFundedAmountSortDown('UNISeqID')
              } else if (clickCount === 2) {
                this.handleFundedAmountSortClear()
              } else {
                this.handleFundedAmountSortClear()
              }
              this.handleHeaderCellClick()
            }
          }
        },
        render: (text: string) => <div style={{ marginLeft: '20%' }}> {text}</div>
      },
      {
        title: 'UNI Program',
        dataIndex: 'UNIProg',
        key: 'UNIProg',
        width: '12%',
        sorter: true,
        onHeaderCell: () => {
          return {
            onClick: () => {
              const { clickCount, currentSort } = this.state
              if (clickCount === 0 || currentSort !== 'UNIProg') {
                this.handleFundedAmountSortUp('UNIProg')
              } else if (clickCount === 1) {
                this.handleFundedAmountSortDown('UNIProg')
              } else if (clickCount === 2) {
                this.handleFundedAmountSortClear()
              } else {
                this.handleFundedAmountSortClear()
              }
              this.handleHeaderCellClick()
            }
          }
        },
        render: (text: string) => <div style={{ marginLeft: '20%' }}> {text}</div>
      },
      {
        title: 'UNI Amount',
        dataIndex: 'UNIAmt',
        key: 'UNIAmt',
        sorter: true,
        width: '10%',
        onHeaderCell: () => {
          return {
            onClick: () => {
              const { clickCount, currentSort } = this.state
              if (clickCount === 0 || currentSort !== 'UNIAmt') {
                this.handleFundedAmountSortUp('UNIAmt')
              } else if (clickCount === 1) {
                this.handleFundedAmountSortDown('UNIAmt')
              } else if (clickCount === 2) {
                this.handleFundedAmountSortClear()
              } else {
                this.handleFundedAmountSortClear()
              }
              this.handleHeaderCellClick()
            }
          }
        },
        render: (text: string) => <div style={{ marginLeft: '20%' }}> {text}</div>
      },
      {
        title: 'FFB Amount',
        dataIndex: 'FFBAmt',
        key: 'FFBAmt',
        sorter: true,
        width: '10%',
        onHeaderCell: () => {
          return {
            onClick: () => {
              const { clickCount, currentSort } = this.state
              if (clickCount === 0 || currentSort !== 'FFBAmt') {
                this.handleFundedAmountSortUp('FFBAmt')
              } else if (clickCount === 1) {
                this.handleFundedAmountSortDown('FFBAmt')
              } else if (clickCount === 2) {
                this.handleFundedAmountSortClear()
              } else {
                this.handleFundedAmountSortClear()
              }
              this.handleHeaderCellClick()
            }
          }
        },
        render: (text: string) => <div style={{ marginLeft: '20%' }}> {text}</div>
      },
      {
        title: 'E6 Amount',
        dataIndex: 'e6amt',
        key: 'e6amt',
        sorter: true,
        width: '10%',
        onHeaderCell: () => {
          return {
            onClick: () => {
              const { clickCount, currentSort } = this.state
              if (clickCount === 0 || currentSort !== 'e6amt') {
                this.handleFundedAmountSortUp('e6amt')
              } else if (clickCount === 1) {
                this.handleFundedAmountSortDown('e6amt')
              } else if (clickCount === 2) {
                this.handleFundedAmountSortClear()
              } else {
                this.handleFundedAmountSortClear()
              }
              this.handleHeaderCellClick()
            }
          }
        },
        render: (text: string) => <div style={{ marginLeft: '20%' }}> {text}</div>
      },
      {
        title: 'FFB Type',
        dataIndex: 'FFBType',
        key: 'FFBType',
        width: '10%',
        sorter: true,
        onHeaderCell: () => {
          return {
            onClick: () => {
              const { clickCount, currentSort } = this.state
              if (clickCount === 0 || currentSort !== 'FFBType') {
                this.handleFundedAmountSortUp('FFBType')
              } else if (clickCount === 1) {
                this.handleFundedAmountSortDown('FFBType')
              } else if (clickCount === 2) {
                this.handleFundedAmountSortClear()
              } else {
                this.handleFundedAmountSortClear()
              }
              this.handleHeaderCellClick()
            }
          }
        }
      },
      {
        title: 'UNI Created Date',
        dataIndex: 'formatUNICreate',
        key: 'formatUNICreate',
        ellipsis: true,
        width: '12%',
        sorter: true,
        onHeaderCell: () => {
          return {
            onClick: () => {
              const { clickCount, currentSort } = this.state
              if (clickCount === 0 || currentSort !== 'UNICreate') {
                this.handleFundedAmountSortUp('UNICreate')
              } else if (clickCount === 1) {
                this.handleFundedAmountSortDown('UNICreate')
              } else if (clickCount === 2) {
                this.handleFundedAmountSortClear()
              } else {
                this.handleFundedAmountSortClear()
              }
              this.handleHeaderCellClick()
            }
          }
        },
        render: (text: string) => <div className={styles.alignmentFormat}>{text}</div>
      },
      {
        title: 'UNI Processed Date',
        dataIndex: 'formatUNIprocessed',
        key: 'formatUNIprocessed',
        ellipsis: true,
        width: '12%',
        sorter: true,
        onHeaderCell: () => {
          return {
            onClick: () => {
              const { clickCount, currentSort } = this.state
              if (clickCount === 0 || currentSort !== 'UNIprocessed') {
                this.handleFundedAmountSortUp('UNIprocessed')
              } else if (clickCount === 1) {
                this.handleFundedAmountSortDown('UNIprocessed')
              } else if (clickCount === 2) {
                this.handleFundedAmountSortClear()
              } else {
                this.handleFundedAmountSortClear()
              }
              this.handleHeaderCellClick()
            }
          }
        },
        render: (text: string) => <div className={styles.alignmentFormat}>{text}</div>
      },
      {
        title: 'Process Time',
        dataIndex: 'Processtime',
        key: 'Processtime',
        ellipsis: true,
        width: '10%',
        sorter: true,
        onHeaderCell: () => {
          return {
            onClick: () => {
              const { clickCount, currentSort } = this.state
              if (clickCount === 0 || currentSort !== 'Processtime') {
                this.handleFundedAmountSortUp('Processtime')
              } else if (clickCount === 1) {
                this.handleFundedAmountSortDown('Processtime')
              } else if (clickCount === 2) {
                this.handleFundedAmountSortClear()
              } else {
                this.handleFundedAmountSortClear()
              }
              this.handleHeaderCellClick()
            }
          }
        },
        render: (text: string) => <div className={styles.alignmentFormat}>{text}</div>
      },
      {
        title: 'UTC Created Date',
        dataIndex: 'formatUTCCreate',
        key: 'formatUTCCreate',
        ellipsis: true,
        width: '12%',
        sorter: true,
        onHeaderCell: () => {
          return {
            onClick: () => {
              const { clickCount, currentSort } = this.state
              if (clickCount === 0 || currentSort !== 'FFBUTCCreate') {
                this.handleFundedAmountSortUp('FFBUTCCreate')
              } else if (clickCount === 1) {
                this.handleFundedAmountSortDown('FFBUTCCreate')
              } else if (clickCount === 2) {
                this.handleFundedAmountSortClear()
              } else {
                this.handleFundedAmountSortClear()
              }
              this.handleHeaderCellClick()
            }
          }
        },
        render: (text: string) => <div className={styles.alignmentFormat}>{text}</div>
      },
      {
        title: 'E6 Transaction ID',
        dataIndex: 'e6txid',
        key: 'e6txid',
        width: '11%',
        sorter: true,
        onHeaderCell: () => {
          return {
            onClick: () => {
              const { clickCount, currentSort } = this.state
              if (clickCount === 0 || currentSort !== 'e6txid') {
                this.handleFundedAmountSortUp('e6txid')
              } else if (clickCount === 1) {
                this.handleFundedAmountSortDown('e6txid')
              } else if (clickCount === 2) {
                this.handleFundedAmountSortClear()
              } else {
                this.handleFundedAmountSortClear()
              }
              this.handleHeaderCellClick()
            }
          }
        },
        render: (text: string) => <div style={{ marginLeft: '20%' }}> {text}</div>
      },
      {
        title: 'UNI File Name',
        dataIndex: 'UNIFile',
        key: 'UNIFile',
        width: '19%',
        sorter: true,
        onHeaderCell: () => {
          return {
            onClick: () => {
              const { clickCount, currentSort } = this.state
              if (clickCount === 0 || currentSort !== 'UNIFile') {
                this.handleFundedAmountSortUp('UNIFile')
              } else if (clickCount === 1) {
                this.handleFundedAmountSortDown('UNIFile')
              } else if (clickCount === 2) {
                this.handleFundedAmountSortClear()
              } else {
                this.handleFundedAmountSortClear()
              }
              this.handleHeaderCellClick()
            }
          }
        },
        render: (text: string) => <div style={{ marginLeft: '5%' }}> {text}</div>
      },
      {
        title: 'FFB File Name',
        dataIndex: 'FFBfile',
        key: 'FFBfile',
        width: '19%',
        sorter: true,
        onHeaderCell: () => {
          return {
            onClick: () => {
              const { clickCount, currentSort } = this.state
              if (clickCount === 0 || currentSort !== 'FFBfile') {
                this.handleFundedAmountSortUp('FFBfile')
              } else if (clickCount === 1) {
                this.handleFundedAmountSortDown('FFBfile')
              } else if (clickCount === 2) {
                this.handleFundedAmountSortClear()
              } else {
                this.handleFundedAmountSortClear()
              }
              this.handleHeaderCellClick()
            }
          }
        },
        render: (text: string) => <div style={{ marginLeft: '5%' }}> {text}</div>
      },
      {
        title: 'E6 File Name',
        dataIndex: 'e6file',
        key: 'e6file',
        width: '19%',
        sorter: true,
        onHeaderCell: () => {
          return {
            onClick: () => {
              const { clickCount, currentSort } = this.state
              if (clickCount === 0 || currentSort !== 'e6file') {
                this.handleFundedAmountSortUp('e6file')
              } else if (clickCount === 1) {
                this.handleFundedAmountSortDown('e6file')
              } else if (clickCount === 2) {
                this.handleFundedAmountSortClear()
              } else {
                this.handleFundedAmountSortClear()
              }
              this.handleHeaderCellClick()
            }
          }
        },
        render: (text: string) => <div style={{ marginLeft: '5%' }}> {text}</div>
      }
    ]

    return (
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <div className={styles.searchFields}>
            <Input
              disabled={this.state.loading}
              placeholder="Input search text"
              allowClear
              className={styles.searchInput}
              onPressEnter={this.handleSearch}
            />
            <div className={styles.selectFields}>
              <span className={styles.selectLabel}>Select search fields: </span>
              <Select
                disabled={this.state.loading}
                defaultValue={'program_id'}
                className={styles.selectSearch}
                onChange={this.handleSearchSelectChange}
                style={{ width: '150px' }} 
              >
                <Option value="pk_id">ID</Option>
                <Option value="program_id">UNI Program</Option>
                <Option value="transaction_type">FFB Type</Option>
                <Option value="taf.file_name">UNI File Name</Option>
                <Option value="FFB.file_name">FFB File Name</Option>
                <Option value="E6.file_name">E6 File Name</Option>
              </Select>
            </div>
          </div>
          <div className={styles.title}>
            <span>
              <h1>From Date: </h1>
            </span>
            <DatePicker
              format={'MM/DD/YYYY'}
              style={{ width: 120 }}
              defaultValue={moment(new Date()).subtract(1, 'M')}
              onChange={(date: any) => {
                this.handleDateChange('fromDate', date)
              }}
            />
          </div>
          <Table
            dataSource={reports}
            columns={columns}
            loading={loading}
            scroll={{ x: 2500 }}
            pagination={{
              total,
              current: page,
              pageSize: limit,
              onChange: this.handlePaginationChange,
              onShowSizeChange: this.handlePageSizeChange,
              showSizeChanger: true
            }}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: { listUserReducer: any }) => {
  return {
    listUserReducer: state.listUserReducer
  }
}

export default connect(mapStateToProps)(ACHRecReport)
