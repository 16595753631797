import { RouteComponentProps } from '@reach/router'
import { Table } from 'antd'
import React from 'react'
import TrackerServices from '../../api/TrackerServices'
import { formatAmount, formatDay } from '../../utils/format'
import styles from './styles.module.css'

interface UserTracking {
  id: string
  label: string
  phone_number: string
  created_date: string
  first_time_deposit: string
  tracker_name: string
}

interface TrackerConponentState {
  data: Array<UserTracking>
  loading: boolean
  errorMessage: string
}

class Tracker extends React.Component<RouteComponentProps, TrackerConponentState> {
  state = {
    data: [],
    loading: false,
    errorMessage: ''
  }

  componentDidMount() {
    const fetchDataTracking = async () => {
      try {
        this.setState({ loading: true })
        const res = await TrackerServices.getUserTracking()

        if (res?.data && Array.isArray(res?.data.data)) {
          this.setState({
            data: res.data.data.map((item: UserTracking) => ({
              ...item,
              createdDate: formatDay(item.created_date, 'DD-MMM-YYYY hh:mm:ss'),
              amount: item.first_time_deposit
                ? formatAmount(parseFloat(item.first_time_deposit))
                : `$0.00`
            })),
            loading: false
          })
        }
      } catch (error: any) {
        this.setState({
          loading: false,
          errorMessage: error.message
        })
      }
    }
    fetchDataTracking()
  }

  render() {
    const columns = [
      {
        title: 'Device ID',
        dataIndex: 'id',
        key: 'id',
        ellipsis: true
      },
      {
        title: 'Phone Number',
        dataIndex: 'phone_number',
        key: 'phone_number',
        ellipsis: true
      },
      {
        title: 'Source',
        dataIndex: 'label',
        key: 'label',
        ellipsis: true
      },
      {
        title: 'Tracker Name',
        dataIndex: 'tracker_name',
        key: 'tracker_name',
        ellipsis: true
      },
      {
        title: 'First Time Deposit Amount',
        dataIndex: 'amount',
        key: 'amount'
      },
      {
        title: 'Created Date',
        dataIndex: 'createdDate',
        key: 'createdDate',
        ellipsis: true
      }
    ]
    return (
      <div className={styles.container}>
        <h1>Tracker</h1>
        <Table dataSource={this.state.data} columns={columns} loading={this.state.loading} />
      </div>
    )
  }
}

export default Tracker
