import HTTPRequest from './index'
import { AxiosResponse } from 'axios'
import { APIs } from '../constants'
import { CryptoAccountBalanceResponse, CryptoTransactionResponse } from 'src/types'

const CryptoServices = {
  getAllRecurringBuys: (): Promise<AxiosResponse['data']> => {
    return HTTPRequest.get(APIs.GET_ALL_RECURRING_BUYS)
  },
  getCryptoTransactionList: (): Promise<CryptoTransactionResponse> => {
    return HTTPRequest.get(APIs.GET_CRYPTO_TRANSACTION_LIST)
  },
  getCryptoAccountBalance: (): Promise<CryptoAccountBalanceResponse> => {
    return HTTPRequest.get(APIs.GET_CRYPTO_ACCOUNT_BALANCE)
  }
}

export default CryptoServices
