import { Table, Switch, Input, DatePicker } from 'antd'
import React from 'react'
import { connect } from 'react-redux'
import styles from './styles.module.css'
import GeminiServices from 'src/api/GeminiServices'
import { formatAmount, formatDay } from 'src/utils/format'
import { CloseOutlined, SearchOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import moment from 'moment'

const { RangePicker } = DatePicker
const defaultRangeOnboard = [moment(new Date()).subtract(60, 'd'), moment(new Date())]

export interface UserGeminiInvoice {
  unifi_username: string
  fi_name: string
  onboarded_date: string
  amount_invested: number
  invested_date: string
  to_date_invested: string
  invested_within_days: string
  trading_requirement: string
  totalOrder: number
}

export interface GeminiInvoiceState {
  users: UserGeminiInvoice[]
  partners: any[]
  loading: boolean
  searchText: string
  filteredInfo: any
  errorMessage: string
  offset: number
  limit: number
  total: number
  searching: boolean
  unifi_username: string
  fi_name: string
  onboarded_date: string
  from_date_invested: string
  to_date_invested: string
  amount_invested: string
  invested_within_days: string
  trading_requirement: string
  clickCount: number
  from_date_onboard: string
  to_date_onboard: string
  onboard_date: string
  investedWithinFilter: any
  fiNameFilter: any
  tradingRequirementFilter: any
}

class GeminiInvoice extends React.PureComponent<any, GeminiInvoiceState> {
  constructor(props: any) {
    super(props)
    this.state = {
      users: [],
      partners: [],
      loading: true,
      searchText: '',
      filteredInfo: {},
      errorMessage: '',
      offset: 1,
      limit: 10,
      total: 0,
      searching: false,
      unifi_username: '',
      fi_name: '',
      onboarded_date: '',
      from_date_invested: '',
      to_date_invested: '',
      amount_invested: '',
      invested_within_days: 'Yes',
      clickCount: 0,
      investedWithinFilter: '',
      fiNameFilter: '',
      trading_requirement: '',
      tradingRequirementFilter: '',
      from_date_onboard: defaultRangeOnboard[0].format('YYYY-MM-DD'),
      to_date_onboard: defaultRangeOnboard[1].format('YYYY-MM-DD'),
      onboard_date: ''
    }
  }

  componentDidMount() {
    this.fetchDataGeminiInvoice()
  }

  fetchDataGeminiInvoice = async () => {
    dayjs.extend(utc)
    const formatPromoDay = 'MMM DD, YYYY'
    const {
      offset,
      limit,
      unifi_username,
      fi_name,
      onboard_date,
      from_date_invested,
      to_date_invested,
      amount_invested,
      invested_within_days,
      trading_requirement,
      from_date_onboard,
      to_date_onboard
    } = this.state
    try {
      this.setState({ loading: true })
      const [res, resPartner] = await Promise.all([
        GeminiServices.getGeminiInvoice(
          offset,
          limit,
          unifi_username,
          fi_name,
          onboard_date,
          from_date_invested,
          to_date_invested,
          amount_invested,
          invested_within_days,
          trading_requirement,
          from_date_onboard,
          to_date_onboard
        ),
        GeminiServices.getPartners()
      ])
      if (res?.data && resPartner?.data) {
        const { data, total } = res.data
        const formatDate = (date: string | null): string => {
          return date ? dayjs.utc(date).format(formatPromoDay) : ''
        }
        const formattedData = data.map((item: UserGeminiInvoice, index: number) => ({
          key: index,
          ...item,
          createdDateOnboarded: formatDate(item.onboarded_date),
          createdDateInvested: formatDate(item.invested_date),
          amountInvested: item.amount_invested ? formatAmount(item.amount_invested) : ` $0.00`
        }))
        this.setState({
          users: formattedData,
          partners: resPartner.data.map((item: any) => {
            return { text: item.partner_name, value: item.partner_name }
          }),
          loading: false,
          total
        })
      }
    } catch (error: any) {
      console.error('Error fetching promo data:', error)
      this.setState({
        loading: false,
        errorMessage: error.message
      })
    }
  }

  handleChange = (filters: any) => {
    this.setState({
      filteredInfo: filters
    })
  }

  handlePaginationChange = (offset: number, pageSize?: number) => {
    this.setState({ offset, limit: pageSize || this.state.limit }, () => {
      this.fetchDataGeminiInvoice()
    })
  }
  handlePageSizeChange = (size: number) => {
    this.setState({ limit: size }, () => {
      this.fetchDataGeminiInvoice()
    })
  }

  handleClearInput = () => {
    this.setState({ unifi_username: '', searchText: '' }, () => {
      this.fetchDataGeminiInvoice()
    })
  }

  handleSearch = async (searchText: string) => {
    this.setState({ unifi_username: searchText }, () => {
      this.fetchDataGeminiInvoice()
    })
  }

  handleFieldChange = (fieldName: keyof GeminiInvoiceState, value: string) => {
    this.setState((prevState) => ({
      ...prevState,
      [fieldName]: value
    }))
  }

  handleFieldClear = (fieldName: keyof GeminiInvoiceState) => {
    this.setState((prevState) => ({
      ...prevState,
      [fieldName]: ''
    }))
  }

  handleDateChange = (fieldDate: keyof GeminiInvoiceState, date: string | null) => {
    const formattedDate = date !== null ? formatDay(date, 'YYYY-MM-DD') : ''
    this.setState(
      (prevState) => ({
        ...prevState,
        [fieldDate]: formattedDate
      }),
      () => {
        this.fetchDataGeminiInvoice()
      }
    )
  }

  handleFundedAmountSortUp = () => {
    this.setState({ amount_invested: 'DESC' }, () => {
      this.fetchDataGeminiInvoice()
    })
  }

  handleFundedAmountSortDown = () => {
    this.setState({ amount_invested: 'ASC' }, () => {
      this.fetchDataGeminiInvoice()
    })
  }

  handleFundedAmountSortClear = () => {
    this.setState({ amount_invested: '', clickCount: -1 }, () => {
      this.fetchDataGeminiInvoice()
    })
  }

  handleHeaderCellClick = () => {
    this.setState((prevState) => ({
      clickCount: prevState.clickCount + 1
    }))
  }

  render() {
    const { users, partners, loading, total, offset, limit, searchText } = this.state
    const columns = [
      {
        title: (
          <div>
            <Input
              placeholder="Username"
              value={searchText}
              onChange={(e) => this.setState({ searchText: e.target.value })}
              style={{ width: 310, marginBottom: 5, display: 'flex' }}
              suffix={
                searchText !== '' ? (
                  <div>
                    <CloseOutlined
                      style={{ color: 'rgba(0, 0, 0, 0.25)', cursor: 'pointer', marginRight: 8 }}
                      onClick={this.handleClearInput}
                    />
                    <SearchOutlined
                      style={{ color: 'rgba(0, 0, 0, 0.25)', cursor: 'pointer' }}
                      onClick={() => this.handleSearch(searchText)}
                    />
                  </div>
                ) : (
                  <SearchOutlined
                    style={{ color: 'rgba(0, 0, 0, 0.25)', cursor: 'pointer' }}
                    onClick={() => this.handleSearch(searchText)}
                  />
                )
              }
            />
          </div>
        ),
        dataIndex: 'unifi_username',
        key: 'unifi_username',
        ellipsis: true,
        width: '24%',
        onFilter: (value: any, record: any) =>
          record.unifi_username.toLowerCase().includes(value.toLowerCase())
      },
      {
        title: 'FI Name',
        dataIndex: 'fi_name',
        key: 'fi_name',
        width: '7%',
        filters: partners,
        filterMultiple: false,
        render: (text: string) => <div className={styles.alignmentFormat}>{text}</div>,
        onFilter: (value: any) => {
          if (value === '') {
            this.handleFieldClear('fi_name')
          } else {
            this.handleFieldChange('fi_name', value)
          }
          return true
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          const { fiNameFilter } = this.state
          if (!visible && !fiNameFilter.fi_name) {
            this.handleFieldClear('fi_name')
          }
        }
      },
      {
        title: (
          <div>
            <DatePicker
              format={'MMM DD, YYYY'}
              placeholder="Onboarded Date"
              style={{ width: 150 }}
              onChange={(date: any) => {
                this.handleDateChange('onboard_date', date)
              }}
            />
          </div>
        ),
        dataIndex: 'createdDateOnboarded',
        key: 'createdDateOnboarded',
        ellipsis: true,
        width: '13%',
        className: styles.centeredTitle,
        render: (text: string) => <div className={styles.alignmentFormat}>{text}</div>
      },
      {
        title: 'Amount Invested',
        dataIndex: 'amountInvested',
        key: 'amountInvested',
        sorter: true,
        width: '12%',
        onHeaderCell: () => {
          return {
            onClick: () => {
              const { clickCount } = this.state
              if (clickCount === 0) {
                this.handleFundedAmountSortUp()
              } else if (clickCount === 1) {
                this.handleFundedAmountSortDown()
              } else if (clickCount === 2) {
                this.handleFundedAmountSortClear()
              } else {
                this.handleFundedAmountSortClear()
              }
              this.handleHeaderCellClick()
            }
          }
        },
        render: (text: string) => <div style={{ marginLeft: '20%' }}> {text}</div>
      },
      {
        title: (
          <div>
            <DatePicker
              format={'MMM DD, YYYY'}
              placeholder="Invested Date"
              style={{ width: 150 }}
              onChange={(date: any) => {
                this.handleDateChange('from_date_invested', date)
                this.handleDateChange('to_date_invested', date)
              }}
            />
          </div>
        ),
        dataIndex: 'createdDateInvested',
        key: 'createdDateInvested',
        ellipsis: true,
        width: '13%',
        render: (text: string) => <div className={styles.alignmentFormat}>{text}</div>
      },
      {
        title: 'Invested Within 60 days?',
        dataIndex: 'invested_within_days',
        key: 'invested_within_days',
        width: '15%',
        render: (text: string, record: UserGeminiInvoice) => {
          return (
            <div style={{ marginLeft: '25%' }}>
              <Switch
                style={{ opacity: 1 }}
                checkedChildren="Yes"
                unCheckedChildren="No"
                checked={record.invested_within_days === 'Yes' ? true : false}
                disabled={true}
              />
            </div>
          )
        },
        filters: [
          { text: 'Yes', value: 'Yes' },
          { text: 'No', value: 'No' }
        ],
        filterMultiple: false,
        defaultFilteredValue: ['Yes'],
        onFilter: (value: any) => {
          if (value === '') {
            this.handleFieldClear('invested_within_days')
          } else {
            this.handleFieldChange('invested_within_days', value)
          }
          return true
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          const { investedWithinFilter } = this.state
          if (!visible && !investedWithinFilter.invested_within_days) {
            this.handleFieldClear('invested_within_days')
          }
        }
      },
      {
        title: 'Trading Requirement?',
        dataIndex: 'trading_requirement',
        key: 'trading_requirement',
        width: '13%',
        render: (text: string, record: UserGeminiInvoice) => {
          return (
            <div style={{ marginLeft: '25%' }}>
              <Switch
                style={{ opacity: 1 }}
                checkedChildren="Yes"
                unCheckedChildren="No"
                checked={record.trading_requirement === 'Yes' ? true : false}
                disabled={true}
              />
            </div>
          )
        },
        filters: [
          { text: 'Yes', value: 'Yes' },
          { text: 'No', value: 'No' }
        ],
        filterMultiple: false,
        onFilter: (value: any) => {
          if (value === '') {
            this.handleFieldClear('trading_requirement')
          } else {
            this.handleFieldChange('trading_requirement', value)
          }
          return true
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          const { tradingRequirementFilter } = this.state
          if (!visible && !tradingRequirementFilter.trading_requirement) {
            this.handleFieldClear('trading_requirement')
          }
        }
      }
    ]

    return (
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <div className={styles.title}>
            <span>
              <h1>Onboarded Date Filter: </h1>
            </span>
            <RangePicker
              format={'MM/DD/YYYY'}
              style={{ width: 250 }}
              defaultValue={[moment(new Date()).subtract(60, 'd'), moment(new Date())]}
              onChange={(_: any, date: any) => {
                this.handleDateChange('from_date_onboard', date[0])
                this.handleDateChange('to_date_onboard', date[1])
              }}
            />
          </div>
          <Table
            dataSource={users}
            columns={columns}
            loading={loading}
            onChange={(filters) => this.handleChange(filters)}
            scroll={{ x: 1400 }}
            pagination={{
              total,
              current: offset,
              pageSize: limit,
              onChange: this.handlePaginationChange,
              onShowSizeChange: this.handlePageSizeChange,
              showSizeChanger: true
            }}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: { listUserReducer: any }) => {
  return {
    listUserReducer: state.listUserReducer
  }
}

export default connect(mapStateToProps)(GeminiInvoice)
