import { Input, Select, Table } from 'antd'
import React from 'react'
import { connect } from 'react-redux'
import styles from './styles.module.css'
import { formatAmount, formatDay } from 'src/utils/format'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import ReconciliationServices from 'src/api/ReconciliationService'
import moment from 'moment'

const { Option } = Select

export interface PYCReport {
  id: string
  partner_name: string
  amount: number
  created_at: string
  updated_at: string
  partner_transaction_id: string
  E6Amt: number
  E6Create: string
  FFBAmt: number
  FFBAcct: string
  external_reference: string
}

export interface PYCReportState {
  reports: PYCReport[]
  loading: boolean
  search: string
  searchBy: string
  sort: string
  sortBy: string
  errorMessage: string
  page: number
  limit: number
  total: number
  searching: boolean
  clickCount: number
  currentSort: string
  fromDate: string
}

class PYCRecReport extends React.PureComponent<any, PYCReportState> {
  constructor(props: any) {
    super(props)
    this.state = {
      reports: [],
      loading: true,
      errorMessage: '',
      page: 1,
      limit: 10,
      total: 0,
      searching: false,
      search: '',
      searchBy: '',
      sort: '',
      sortBy: '',
      clickCount: 0,
      currentSort: '',
      fromDate: ''
    }
  }

  componentDidMount() {
    this.fetchDataReport()
  }

  fetchDataReport = async () => {
    dayjs.extend(utc)
    const formatDay = 'DD-MMM-YYYY'
    const { page, limit, search, searchBy, sort, sortBy } = this.state
    try {
      this.setState({ loading: true })
      const res = await ReconciliationServices.getReportPYC(
        page,
        limit,
        searchBy,
        search,
        sortBy,
        sort
      )
      if (res?.data) {
        const { data, total } = res.data
        const formatDate = (date: string | null): string => {
          return date ? dayjs.utc(date).format(formatDay) : ''
        }
        const formattedData = data.map((item: PYCReport, index: number) => ({
          key: index,
          ...item,
          formatCreatedAt: formatDate(item.created_at),
          formatUpdatedAt: formatDate(item.updated_at),
          formatE6Create: formatDate(item.E6Create),
          amountUNI: item.amount ? formatAmount(item.amount) : '$0.00',
          amountFFB: item.FFBAmt ? formatAmount(item.FFBAmt) : '$0.00',
          amountE6: item.E6Amt ? formatAmount(item.E6Amt) : '$0.00'
        }))
        this.setState({
          reports: formattedData,
          loading: false,
          total
        })
      }
    } catch (error: any) {
      console.error('Error fetching PYC report data:', error)
      this.setState({
        loading: false,
        errorMessage: error.message
      })
    }
  }

  handlePaginationChange = (page: number, pageSize?: number) => {
    this.setState({ page, limit: pageSize || this.state.limit }, () => {
      this.fetchDataReport()
    })
  }
  handlePageSizeChange = (size: number) => {
    this.setState({ limit: size }, () => {
      this.fetchDataReport()
    })
  }

  handleSearchSelectChange = (value: any) => {
    this.setState({
      searchBy: value
    })
  }

  handleClearInput = () => {
    this.setState({ search: '', searchBy: '' }, () => {
      this.fetchDataReport()
    })
  }

  handleSearch = async (event: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
    const value = (event.target as HTMLInputElement).value

    if (this.state.searchBy) {
      this.setState({ search: value, page: 1 }, () => {
        this.fetchDataReport()
      })
    } else {
      this.setState({ searchBy: 'partner_transaction_id', search: value, page: 1 }, () => {
        this.fetchDataReport()
      })
    }
  }

  handleFieldChange = (fieldName: keyof PYCReportState, value: string) => {
    this.setState((prevState) => ({
      ...prevState,
      [fieldName]: value
    }))
  }

  handleFieldClear = (fieldName: keyof PYCReportState) => {
    this.setState((prevState) => ({
      ...prevState,
      [fieldName]: ''
    }))
  }

  handleDateChange = (fieldDate: keyof PYCReportState, date: string | null) => {
    const formattedDate =
      date !== null
        ? formatDay(date, 'YYYY-MM-DD')
        : formatDay(moment(new Date()).subtract(1, 'M') as any, 'YYYY-MM-DD')
    this.setState(
      (prevState) => ({
        ...prevState,
        [fieldDate]: formattedDate
      }),
      () => {
        this.fetchDataReport()
      }
    )
  }

  handleFundedAmountSortUp = (sortBy: string) => {
    this.setState({ sortBy, sort: 'DESC', currentSort: sortBy, clickCount: 0 }, () => {
      this.fetchDataReport()
    })
  }

  handleFundedAmountSortDown = (sortBy: string) => {
    this.setState({ sortBy, sort: 'ASC' }, () => {
      this.fetchDataReport()
    })
  }

  handleFundedAmountSortClear = () => {
    this.setState({ sortBy: '', sort: '', clickCount: -1 }, () => {
      this.fetchDataReport()
    })
  }

  handleHeaderCellClick = () => {
    this.setState((prevState) => ({
      clickCount: prevState.clickCount + 1
    }))
  }

  render() {
    const { reports, loading, total, page, limit } = this.state
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        sorter: true,
        width: '6%',
        onHeaderCell: () => {
          return {
            onClick: () => {
              const { clickCount, currentSort } = this.state
              if (clickCount === 0 || currentSort !== 'te.id') {
                this.handleFundedAmountSortUp('te.id')
              } else if (clickCount === 1) {
                this.handleFundedAmountSortDown('te.id')
              } else if (clickCount === 2) {
                this.handleFundedAmountSortClear()
              } else {
                this.handleFundedAmountSortClear()
              }
              this.handleHeaderCellClick()
            }
          }
        },
        render: (text: string) => <div style={{ marginLeft: '10%' }}> {text}</div>
      },
      {
        title: 'Partner Name',
        dataIndex: 'partner_name',
        key: 'partner_name',
        width: '12%',
        render: (text: string) => <div style={{ marginLeft: '5%' }}> {text}</div>
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        sorter: true,
        width: '10%',
        onHeaderCell: () => {
          return {
            onClick: () => {
              const { clickCount, currentSort } = this.state
              if (clickCount === 0 || currentSort !== 'te.amount') {
                this.handleFundedAmountSortUp('te.amount')
              } else if (clickCount === 1) {
                this.handleFundedAmountSortDown('te.amount')
              } else if (clickCount === 2) {
                this.handleFundedAmountSortClear()
              } else {
                this.handleFundedAmountSortClear()
              }
              this.handleHeaderCellClick()
            }
          }
        },
        render: (text: string) => <div style={{ marginLeft: '10%' }}> {text}</div>
      },
      {
        title: 'Created At',
        dataIndex: 'formatCreatedAt',
        key: 'formatCreatedAt',
        sorter: true,
        width: '10%',
        onHeaderCell: () => {
          return {
            onClick: () => {
              const { clickCount, currentSort } = this.state
              if (clickCount === 0 || currentSort !== 'te.created_at') {
                this.handleFundedAmountSortUp('te.created_at')
              } else if (clickCount === 1) {
                this.handleFundedAmountSortDown('te.created_at')
              } else if (clickCount === 2) {
                this.handleFundedAmountSortClear()
              } else {
                this.handleFundedAmountSortClear()
              }
              this.handleHeaderCellClick()
            }
          }
        },
        render: (text: string) => <div style={{ marginLeft: '10%' }}> {text}</div>
      },
      {
        title: 'Updated At',
        dataIndex: 'formatUpdatedAt',
        key: 'formatUpdatedAt',
        sorter: true,
        width: '10%',
        onHeaderCell: () => {
          return {
            onClick: () => {
              const { clickCount, currentSort } = this.state
              if (clickCount === 0 || currentSort !== 'te.updated_at') {
                this.handleFundedAmountSortUp('te.updated_at')
              } else if (clickCount === 1) {
                this.handleFundedAmountSortDown('te.updated_at')
              } else if (clickCount === 2) {
                this.handleFundedAmountSortClear()
              } else {
                this.handleFundedAmountSortClear()
              }
              this.handleHeaderCellClick()
            }
          }
        },
        render: (text: string) => <div style={{ marginLeft: '10%' }}> {text}</div>
      },
      {
        title: 'Partner Trans ID',
        dataIndex: 'partner_transaction_id',
        key: 'partner_transaction_id',
        width: '15%',
        sorter: true,
        onHeaderCell: () => {
          return {
            onClick: () => {
              const { clickCount, currentSort } = this.state
              if (clickCount === 0 || currentSort !== 'te.partner_transaction_id') {
                this.handleFundedAmountSortUp('te.partner_transaction_id')
              } else if (clickCount === 1) {
                this.handleFundedAmountSortDown('te.partner_transaction_id')
              } else if (clickCount === 2) {
                this.handleFundedAmountSortClear()
              } else {
                this.handleFundedAmountSortClear()
              }
              this.handleHeaderCellClick()
            }
          }
        },
        render: (text: string) => <div style={{ marginLeft: '10%' }}> {text}</div>
      },
      {
        title: 'E6 Amount',
        dataIndex: 'E6Amt',
        key: 'E6Amt',
        ellipsis: true,
        width: '10%',
        sorter: true,
        onHeaderCell: () => {
          return {
            onClick: () => {
              const { clickCount, currentSort } = this.state
              if (clickCount === 0 || currentSort !== 'E6Amt') {
                this.handleFundedAmountSortUp('E6Amt')
              } else if (clickCount === 1) {
                this.handleFundedAmountSortDown('E6Amt')
              } else if (clickCount === 2) {
                this.handleFundedAmountSortClear()
              } else {
                this.handleFundedAmountSortClear()
              }
              this.handleHeaderCellClick()
            }
          }
        },
        render: (text: string) => <div className={styles.alignmentFormat}>{text}</div>
      },
      {
        title: 'E6 Created Date',
        dataIndex: 'E6Create',
        key: 'E6Create',
        ellipsis: true,
        width: '10%',
        sorter: true,
        onHeaderCell: () => {
          return {
            onClick: () => {
              const { clickCount, currentSort } = this.state
              if (clickCount === 0 || currentSort !== 'formatE6Create') {
                this.handleFundedAmountSortUp('formatE6Create')
              } else if (clickCount === 1) {
                this.handleFundedAmountSortDown('formatE6Create')
              } else if (clickCount === 2) {
                this.handleFundedAmountSortClear()
              } else {
                this.handleFundedAmountSortClear()
              }
              this.handleHeaderCellClick()
            }
          }
        },
        render: (text: string) => <div className={styles.alignmentFormat}>{text}</div>
      },
      {
        title: 'FFB Amount',
        dataIndex: 'FFBAmt',
        key: 'FFBAmt',
        ellipsis: true,
        width: '10%',
        sorter: true,
        onHeaderCell: () => {
          return {
            onClick: () => {
              const { clickCount, currentSort } = this.state
              if (clickCount === 0 || currentSort !== 'FFBAmt') {
                this.handleFundedAmountSortUp('FFBAmt')
              } else if (clickCount === 1) {
                this.handleFundedAmountSortDown('FFBAmt')
              } else if (clickCount === 2) {
                this.handleFundedAmountSortClear()
              } else {
                this.handleFundedAmountSortClear()
              }
              this.handleHeaderCellClick()
            }
          }
        },
        render: (text: string) => <div className={styles.alignmentFormat}>{text}</div>
      },
      {
        title: 'FFB Account',
        dataIndex: 'FFBAcct',
        key: 'FFBAcct',
        ellipsis: true,
        width: '10%',
        sorter: true,
        onHeaderCell: () => {
          return {
            onClick: () => {
              const { clickCount, currentSort } = this.state
              if (clickCount === 0 || currentSort !== 'FFBAcct') {
                this.handleFundedAmountSortUp('FFBAcct')
              } else if (clickCount === 1) {
                this.handleFundedAmountSortDown('FFBAcct')
              } else if (clickCount === 2) {
                this.handleFundedAmountSortClear()
              } else {
                this.handleFundedAmountSortClear()
              }
              this.handleHeaderCellClick()
            }
          }
        },
        render: (text: string) => <div className={styles.alignmentFormat}>{text}</div>
      },
      {
        title: 'E6 External Reference',
        dataIndex: 'external_reference',
        key: 'external_reference',
        width: '11%',
        sorter: true,
        onHeaderCell: () => {
          return {
            onClick: () => {
              const { clickCount, currentSort } = this.state
              if (clickCount === 0 || currentSort !== 'E6.external_reference') {
                this.handleFundedAmountSortUp('E6.external_reference')
              } else if (clickCount === 1) {
                this.handleFundedAmountSortDown('E6.external_reference')
              } else if (clickCount === 2) {
                this.handleFundedAmountSortClear()
              } else {
                this.handleFundedAmountSortClear()
              }
              this.handleHeaderCellClick()
            }
          }
        },
        render: (text: string) => <div style={{ marginLeft: '20%' }}> {text}</div>
      }
    ]

    return (
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <div className={styles.searchFields}>
            <Input
              disabled={this.state.loading}
              placeholder="Input search text"
              allowClear
              className={styles.searchInput}
              onPressEnter={this.handleSearch}
            />
            <div className={styles.selectFields}>
              <span className={styles.selectLabel}>Select search fields: </span>
              <Select
                disabled={this.state.loading}
                defaultValue={'partner_transaction_id'}
                className={styles.selectSearch}
                onChange={this.handleSearchSelectChange}
                style={{ width: '180px' }} 
              >
                <Option value="id">ID</Option>
                <Option value="partner_transaction_id">Partner Trans ID</Option>
                <Option value="FFB.custAcct_last4 ">FFB Account</Option>
                <Option value="E6.external_reference">E6 External Reference</Option>
              </Select>
            </div>
          </div>
          <h5 className={styles.resultText}></h5>
          <Table
            dataSource={reports}
            columns={columns}
            loading={loading}
            scroll={{ x: 2000 }}
            pagination={{
              total,
              current: page,
              pageSize: limit,
              onChange: this.handlePaginationChange,
              onShowSizeChange: this.handlePageSizeChange,
              showSizeChanger: true
            }}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: { listUserReducer: any }) => {
  return {
    listUserReducer: state.listUserReducer
  }
}

export default connect(mapStateToProps)(PYCRecReport)
