import React from "react"
import { RouteComponentProps, navigate } from "@reach/router"
import { UserContext } from "../../context/auth"
import { Button, Result } from "antd"
import axios from "axios"

const Q2CallbackPage = (props: RouteComponentProps) => {
  const loginWithSSO = async (login: () => void) => {
    let query: any = props?.location?.search
    let regex = /[?&;](.+?)=([^&;]+)/g
    let params: any = {}
    if (query) {
      let match
      while (match === regex.exec(query)) {
        params[match[1]] = decodeURIComponent(match[2])
      }
    }
    console.log("params >>> ", params)
    const config: any = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/auth/q2-login?code=${params.code}`,
    }
    const response = await axios(config).catch(err => {
      console.log(err)
      return null
    })
    if (response) {
      const accessToken = response.data.accessToken
      await sessionStorage.setItem("jwtToken", accessToken.jwtToken)
      await sessionStorage.setItem("username", accessToken.payload.username)
      login()
      navigate("/home/dashboard")
    } else {
      alert("UnAuthorization")
    }
  }
  return (
    <UserContext.Consumer>
      {({ login }) => (
        <>
          <Result
            status="404"
            title="200"
            subTitle="Q2 Page Callback"
            extra={
              <Button onClick={() => loginWithSSO(login)}>
                Continue Authentication with SSO
              </Button>
            }
          />
        </>
      )}
    </UserContext.Consumer>
  )
}

export default Q2CallbackPage
