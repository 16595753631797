import React from "react"
import { Button, Result } from "antd"
import { UserContext } from "../../context/auth"
import {RouteComponentProps, navigate} from '@reach/router'

const ForbiddenPage = (props: RouteComponentProps) => {
  const goToLogin = async (logout: () => void) => {
    logout()
    navigate("/")
  }
  return (
    <UserContext.Consumer>
      {({ logout }) => (
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page. Please login again!"
          extra={
            <Button onClick={() => goToLogin(logout)}>Login</Button>
          }
        />
      )}
    </UserContext.Consumer>
  )
}

export default ForbiddenPage
