// import './wdyr'
import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./containers"
import reportWebVitals from "./reportWebVitals"

// if (process.env.NODE_ENV === 'development') {
// const whyDidYouRender = require('@welldone-software/why-did-you-render');
// whyDidYouRender(React, {
//   onlyLogs: true,
// titleColor: "green",
// diffNameColor: "aqua",
// trackAllPureComponents: true
//   });
// }

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
