export const ATTRIBUTES_FIELDS = [
  'phone_number',
  'family_name',
  'name',
  'email',
  'email_verified',
  'birthdate'
]

export const SymbolCode = {
  PLATINUM: 'FPNOZ',
  GOLD: 'FGNOK',
  SILVER: 'FSNHZ'
}

export const APIs = {
  GET_USER_LIST: '/users',
  GET_USER_TRACKING: '/users/tracking?page=0&limit=10',
  EXPORT_ZERO_BALANCE: '/report/zero-balance',
  GET_UMB_ACCOUNT_BALANCE: '/transactions/umb-account-balance',
  GET_UMB_TRANSACTION_LIST: '/transactions/umb',
  GET_E6_ACCOUNT_BALANCE: '/users/e6-balance',
  GET_E6_TRANSACTION_LIST: '/transactions/e6',
  GET_USER_COGNITO: '/users/cognito',
  GET_LINKED_BANK: '/users/accounts',
  SEARCHING_USER_LIST: '/users/searching',
  GET_V2_PROMOTION_BACK_OFFICE: '/promo',
  GET_V2_GEMINI_INVOICE: '/gemini/invoice',
  GET_V2_GEMINI_PARTNER: '/gemini/partner',

  // metal
  GET_GBI_TRANSACTION_LIST: '/transactions/gbi',
  GET_GBI_ACCOUNT_HOLDING: '/users/gbi-account-holding',

  // crypto
  GET_ALL_RECURRING_BUYS: '/gemini/recurring-order-config-entries-all',
  GET_CRYPTO_TRANSACTION_LIST: '/transactions/gemini',
  GET_CRYPTO_ACCOUNT_BALANCE: '/users/gemini-account-balance',

  // robo
  GET_DW_ROBO_ACCOUNT_BALANCE: '/transactions/robo-account-balance',
  GET_APEX_ROBO_ACCOUNT_BALANCE: '/apex/robo-summary',

  // self-directed
  GET_DW_SELF_ACCOUNT_BALANCE: '/transactions/self-directed-account-balance',
  GET_APEX_SELF_ACCOUNT_BALANCE: '/apex/self-summary',

  // transaction robo or self
  GET_DW_TRANSACTION_LIST: '/transactions/drive-wealth-transaction',
  GET_APEX_TRANSACTION_LIST: '/apex/list-transaction-order',

  // deposit
  GET_SPECIAL_REQUEST_TRANSACTIONS: '/transactions/deposit',
  UPDATE_PENDING_TRANSACTION: '/transactions/deposit',

  // reconciliation report
  GET_REPORT_ACH_AFT: '/report/report-ach',
  GET_REPORT_PYC: '/report/report-pyc'
}

export const TabPaneKey = {
  DD_ACCOUNT: '1',
  ROBO: '2',
  SELF_ELECTED: '3',
  CRYPTO: '4',
  PRECIOUS_METAL: '5',
  LINKED_BANKS: '6',
  KYC: '7'
}
