import dayjs from "dayjs"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"

dayjs.extend(isSameOrBefore)
export const sortAlphaBeta = (a: string, b: string): number => {
  const nameA = a.toLowerCase()
  const nameB = b.toLowerCase()
  if (nameA < nameB) return -1
  if (nameA > nameB) return 1
  return 0
}

export const sortDateIsSameOrBefore = (a: string, b: string): number => {
  const dayA = dayjs(a)
  const dayB = dayjs(b)
  if (dayA.isSameOrBefore(dayB)) return 1
  return -1
}
